import { styled } from '@mui/material/styles';
import { Box, Button, TextField } from '@mui/material';

export const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#f5f5f5',
});

export const Card = styled(Box)(({ theme, isLoginForm }) => ({
  display: 'flex',
  width: '900px',
  height: '800px',
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  overflow: 'hidden',
  position: 'relative',
}));

export const Panel = styled(Box)(({ theme, isForm, isDark }) => ({
  width: '50%',
  height: '100%',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: isDark ? '#fff' : theme.palette.text.primary,
  backgroundColor: isDark ? '#333' : '#fff',
  position: 'absolute',
  top: 0,
  left: isForm ? '0%' : '50%',
  transition: 'left 0.6s ease-in-out',
  zIndex: isForm ? 2 : 1,
}));

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '300px',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));