import React, { useState, useEffect } from 'react';
import { 
  Grid, Paper, Typography, TextField, Button, Avatar, Box, Snackbar, Alert,
  Tabs, Tab, IconButton, Chip, List, ListItem, ListItemText, ListItemSecondaryAction,
  CircularProgress, Divider, Card, CardContent, CardHeader, Tooltip, Dialog, DialogTitle,
  DialogContent, DialogActions, LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Edit, Save, Cancel, Add, Delete, LinkedIn, Twitter, CalendarToday,
  Work, Phone, Email, Home, Person, Cake, School, EmojiEvents, LocalLibrary,
  Visibility, VisibilityOff, Lock, Facebook, Instagram
} from '@mui/icons-material';
import { getUserProfile, updateUserProfile, uploadProfilePicture, getUserSkills, updateUserSkills, addUserSkill, deleteUserSkill } from '../services/api';
import PasswordChange from './PasswordChange';
import Chart from 'react-apexcharts';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .05)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
	transform: 'translateY(-5px)',
  },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 200,
  height: 200,
  margin: 'auto',
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: '0 0 20px rgba(0,0,0,0.2)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
	transform: 'scale(1.05)',
  },
}));

const SkillChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  transition: 'all 0.3s',
  '&:hover': {
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
  },
}));

const UserProfile = () => {
  const [profile, setProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState('');
  const [skillDialogOpen, setSkillDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
	fetchUserProfile();
	fetchUserSkills();
  }, []);

  useEffect(() => {
	if (profile && profile.profileImage && profile.profileImage.data) {
	  setProfileImageUrl(`data:${profile.profileImage.contentType};base64,${profile.profileImage.data}`);
	}
  }, [profile]);

  const fetchUserProfile = async () => {
	try {
	  const response = await getUserProfile();
	  setProfile(response.data);
	  setLoading(false);
	} catch (err) {
	  setSnackbar({ open: true, message: 'Failed to load user profile', severity: 'error' });
	  setLoading(false);
	}
  };

  const handleInputChange = (e) => {
	const { name, value } = e.target;
	setProfile(prev => ({
	  ...prev,
	  [name]: value
	}));
  };

  const handleSubmit = async () => {
	try {
	  await updateUserProfile(profile);
	  setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
	  setEditMode(false);
	} catch (err) {
	  setSnackbar({ open: true, message: 'Failed to update profile', severity: 'error' });
	}
  };

  const handleProfilePictureUpload = async (event) => {
	const file = event.target.files[0];
	if (file) {
	  try {
		const formData = new FormData();
		formData.append('profilePicture', file);
		await uploadProfilePicture(formData);
		fetchUserProfile();
		setSnackbar({ open: true, message: 'Profile picture updated successfully', severity: 'success' });
	  } catch (err) {
		setSnackbar({ open: true, message: 'Failed to update profile picture', severity: 'error' });
	  }
	}
  };

  const fetchUserSkills = async () => {
	try {
	  const response = await getUserSkills();
	  setSkills(response.data);
	} catch (err) {
	  setSnackbar({ open: true, message: 'Failed to load user skills', severity: 'error' });
	}
  };
  
  const handleAddSkill = async () => {
	if (newSkill.trim()) {
	  try {
		await addUserSkill(newSkill.trim());
		setSkills(prev => [...prev, newSkill.trim()]);
		setNewSkill('');
		setSkillDialogOpen(false);
		setSnackbar({ open: true, message: 'Skill added successfully', severity: 'success' });
	  } catch (err) {
		setSnackbar({ open: true, message: 'Failed to add skill', severity: 'error' });
	  }
	}
  };
  
  const handleDeleteSkill = async (skillToDelete) => {
	try {
	  await deleteUserSkill(skillToDelete);
	  setSkills(prev => prev.filter(skill => skill !== skillToDelete));
	  setSnackbar({ open: true, message: 'Skill deleted successfully', severity: 'success' });
	} catch (err) {
	  setSnackbar({ open: true, message: 'Failed to delete skill', severity: 'error' });
	}
  };

  if (loading) return <CircularProgress />;
  if (!profile) return <Typography>No profile data available.</Typography>;

  const completionPercentage = calculateProfileCompletion(profile);

  return (
	<Grid container spacing={3}>
	  <Grid item xs={12}>
		<Typography variant="h4" gutterBottom>My Profile</Typography>
		<LinearProgress variant="determinate" value={completionPercentage} />
		<Typography variant="body2" color="textSecondary" align="right">
		  Profile Completion: {completionPercentage}%
		</Typography>
	  </Grid>
	  <Grid item xs={12} md={4}>
		<StyledPaper>
		  <Box display="flex" flexDirection="column" alignItems="center">
			<input
			  accept="image/*"
			  style={{ display: 'none' }}
			  id="profile-picture-upload"
			  type="file"
			  onChange={handleProfilePictureUpload}
			/>
			<label htmlFor="profile-picture-upload">
			  <Tooltip title="Click to change profile picture">
				<ProfileAvatar 
				  src={profileImageUrl} 
				  alt={profile?.name || 'User'}
				>
				  {profile?.name ? profile.name.charAt(0).toUpperCase() : ''}
				</ProfileAvatar>
			  </Tooltip>
			</label>
			<Typography variant="h5" mt={2}>{profile.name}</Typography>
			<Typography variant="body1" color="textSecondary">{profile.position}</Typography>
			<Chip 
			  label={profile.role} 
			  color="primary" 
			  style={{ marginTop: 8 }}
			/>
			{!editMode && (
			  <Button
				startIcon={<Edit />}
				onClick={() => setEditMode(true)}
				style={{ marginTop: 16 }}
			  >
				Edit Profile
			  </Button>
			)}
		  </Box>
		  <Divider style={{ margin: '20px 0' }} />
		  <Typography variant="h6" gutterBottom>Skills</Typography>
		  <Box display="flex" flexWrap="wrap" justifyContent="center">
			{skills.map((skill, index) => (
			  <SkillChip
				key={index}
				label={skill}
				onDelete={editMode ? () => handleDeleteSkill(skill) : undefined}
			  />
			))}
			{editMode && (
			  <Chip
				icon={<Add />}
				label="Add Skill"
				onClick={() => setSkillDialogOpen(true)}
				color="primary"
				variant="outlined"
			  />
			)}
		  </Box>
		</StyledPaper>
		<StyledPaper>
		  <Typography variant="h6" gutterBottom>Performance Overview</Typography>
		  <Chart
			options={{
			  chart: { id: 'performance-chart' },
			  xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'] },
			  stroke: { curve: 'smooth' },
			}}
			series={[{ name: 'Performance', data: [30, 40, 45, 50, 49, 60] }]}
			type="line"
			height={250}
		  />
		</StyledPaper>
	  </Grid>
	  <Grid item xs={12} md={8}>
		<StyledPaper>
		  <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
			<Tab label="Personal Info" />
			<Tab label="Work Info" />
			<Tab label="Education & Achievements" />
			<Tab label="Settings" />
		  </Tabs>
		  <Box mt={3}>
			{activeTab === 0 && (
			  <List>
				<ListItem>
				  <ListItemText 
					primary="Email" 
					secondary={
					  editMode ? 
						<TextField 
						  name="email" 
						  value={profile.email} 
						  onChange={handleInputChange} 
						  fullWidth
						/> : 
						profile.email
					} 
				  />
				  <ListItemSecondaryAction>
					<IconButton edge="end" aria-label="email">
					  <Email />
					</IconButton>
				  </ListItemSecondaryAction>
				</ListItem>
				<ListItem>
				  <ListItemText 
					primary="Phone" 
					secondary={
					  editMode ? 
						<TextField 
						  name="phoneNumber" 
						  value={profile.phoneNumber} 
						  onChange={handleInputChange} 
						  fullWidth
						/> : 
						profile.phoneNumber
					} 
				  />
				  <ListItemSecondaryAction>
					<IconButton edge="end" aria-label="phone">
					  <Phone />
					</IconButton>
				  </ListItemSecondaryAction>
				</ListItem>
				<ListItem>
				  <ListItemText 
					primary="Address" 
					secondary={
					  editMode ? 
						<TextField 
						  name="address" 
						  value={profile.address} 
						  onChange={handleInputChange} 
						  fullWidth
						/> : 
						profile.address
					} 
				  />
				  <ListItemSecondaryAction>
					<IconButton edge="end" aria-label="address">
					  <Home />
					</IconButton>
				  </ListItemSecondaryAction>
				</ListItem>
				<ListItem>
				  <ListItemText 
					primary="Date of Birth" 
					secondary={
					  editMode ? 
						<TextField 
						  name="dateOfBirth" 
						  type="date" 
						  value={profile.dateOfBirth} 
						  onChange={handleInputChange} 
						  fullWidth
						  InputLabelProps={{ shrink: true }}
						/> : 
						new Date(profile.dateOfBirth).toLocaleDateString()
					} 
				  />
				  <ListItemSecondaryAction>
					<IconButton edge="end" aria-label="birthday">
					  <Cake />
					</IconButton>
				  </ListItemSecondaryAction>
				</ListItem>
			  </List>
			)}
			{activeTab === 1 && (
			  <List>
				<ListItem>
				  <ListItemText 
					primary="Department" 
					secondary={
					  editMode ? 
						<TextField 
						  name="department" 
						  value={profile.department} 
						  onChange={handleInputChange} 
						  fullWidth
						/> : 
						profile.department
					} 
				  />
				  <ListItemSecondaryAction>
					<IconButton edge="end" aria-label="department">
					  <Work />
					</IconButton>
				  </ListItemSecondaryAction>
				</ListItem>
				<ListItem>
				  <ListItemText 
					primary="Position" 
					secondary={
					  editMode ? 
						<TextField 
						  name="position" 
						  value={profile.position} 
						  onChange={handleInputChange} 
						  fullWidth
						/> : 
						profile.position
					} 
				  />
				  <ListItemSecondaryAction>
					<IconButton edge="end" aria-label="position">
					  <Person />
					</IconButton>
				  </ListItemSecondaryAction>
				</ListItem>
				<ListItem>
				  <ListItemText 
					primary="Hire Date" 
					secondary={
					  editMode ? 
						<TextField 
						  name="hireDate" 
						  type="date" 
						  value={profile.hireDate} 
						  onChange={handleInputChange} 
						  fullWidth
						  InputLabelProps={{ shrink: true }}
						/> : 
						new Date(profile.hireDate).toLocaleDateString()
					} 
				  />
				  <ListItemSecondaryAction>
					<IconButton edge="end" aria-label="hire date">
					  <CalendarToday />
					</IconButton>
				  </ListItemSecondaryAction>
				</ListItem>
			  </List>
			)}
			{activeTab === 2 && (
			  <Box>
				<Typography variant="h6" gutterBottom>Education</Typography>
				<List>
				  {profile.education?.map((edu, index) => (
					<ListItem key={index}>
					  <ListItemText
						primary={edu.degree}
						secondary={`${edu.school} (${edu.year})`}
					  />
					  <ListItemSecondaryAction>
						<IconButton edge="end" aria-label="school">
						  <School />
						</IconButton>
					  </ListItemSecondaryAction>
					</ListItem>
				  ))}
				</List>
				<Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>Achievements</Typography>
				<List>
				  {profile.achievements?.map((achievement, index) => (
					<ListItem key={index}>
					  <ListItemText
						primary={achievement.title}
						secondary={achievement.date}
					  />
					  <ListItemSecondaryAction>
						<IconButton edge="end" aria-label="achievement">
					  	<EmojiEvents />
						</IconButton>
				  	</ListItemSecondaryAction>
					</ListItem>
			  	))}
				</List>
				<Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>Certifications</Typography>
				<List>
			  	{profile.certifications?.map((cert, index) => (
					<ListItem key={index}>
				  	<ListItemText
						primary={cert.name}
						secondary={`Issued by: ${cert.issuedBy} (${cert.year})`}
				  	/>
				  	<ListItemSecondaryAction>
						<IconButton edge="end" aria-label="certification">
					  	<LocalLibrary />
						</IconButton>
				  	</ListItemSecondaryAction>
					</ListItem>
			  	))}
				</List>
		  	</Box>
			)}
			{activeTab === 3 && (
		  	<Box>
				<Typography variant="h6" gutterBottom>Change Password</Typography>
				<PasswordChange />
				<Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>Social Links</Typography>
				<TextField
			  	fullWidth
			  	label="LinkedIn"
			  	name="socialLinks.linkedin"
			  	value={profile.socialLinks?.linkedin || ''}
			  	onChange={handleInputChange}
			  	InputProps={{
					startAdornment: <LinkedIn />,
			  	}}
			  	style={{ marginBottom: 8 }}
				/>
				<TextField
			  	fullWidth
			  	label="Twitter"
			  	name="socialLinks.twitter"
			  	value={profile.socialLinks?.twitter || ''}
			  	onChange={handleInputChange}
			  	InputProps={{
					startAdornment: <Twitter />,
			  	}}
			  	style={{ marginBottom: 8 }}
				/>
				<TextField
			  	fullWidth
			  	label="Facebook"
			  	name="socialLinks.facebook"
			  	value={profile.socialLinks?.facebook || ''}
			  	onChange={handleInputChange}
			  	InputProps={{
					startAdornment: <Facebook />,
			  	}}
			  	style={{ marginBottom: 8 }}
				/>
				<TextField
			  	fullWidth
			  	label="Instagram"
			  	name="socialLinks.instagram"
			  	value={profile.socialLinks?.instagram || ''}
			  	onChange={handleInputChange}
			  	InputProps={{
					startAdornment: <Instagram />,
			  	}}
				/>
				<Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>Privacy Settings</Typography>
				<List>
			  	<ListItem>
					<ListItemText primary="Profile Visibility" />
					<ListItemSecondaryAction>
				  	<IconButton onClick={() => setProfile(prev => ({ ...prev, isPublic: !prev.isPublic }))}>
						{profile.isPublic ? <Visibility /> : <VisibilityOff />}
				  	</IconButton>
					</ListItemSecondaryAction>
			  	</ListItem>
			  	<ListItem>
					<ListItemText primary="Two-Factor Authentication" />
					<ListItemSecondaryAction>
				  	<IconButton onClick={() => setProfile(prev => ({ ...prev, twoFactorEnabled: !prev.twoFactorEnabled }))}>
						{profile.twoFactorEnabled ? <Lock color="primary" /> : <Lock color="action" />}
				  	</IconButton>
					</ListItemSecondaryAction>
			  	</ListItem>
				</List>
		  	</Box>
			)}
	  	</Box>
	  	{editMode && (
			<Box mt={3} display="flex" justifyContent="flex-end">
		  	<Button 
				startIcon={<Cancel />} 
				onClick={() => setEditMode(false)} 
				style={{ marginRight: 8 }}
		  	>
				Cancel
		  	</Button>
		  	<Button 
				startIcon={<Save />} 
				variant="contained" 
				color="primary" 
				onClick={handleSubmit}
		  	>
				Save Changes
		  	</Button>
			</Box>
	  	)}
		</StyledPaper>
  	</Grid>
  	<Dialog open={skillDialogOpen} onClose={() => setSkillDialogOpen(false)}>
		<DialogTitle>Add New Skill</DialogTitle>
		<DialogContent>
	  	<TextField
			autoFocus
			margin="dense"
			label="Skill"
			fullWidth
			value={newSkill}
			onChange={(e) => setNewSkill(e.target.value)}
	  	/>
		</DialogContent>
		<DialogActions>
	  	<Button onClick={() => setSkillDialogOpen(false)}>Cancel</Button>
	  	<Button onClick={handleAddSkill} color="primary">Add</Button>
		</DialogActions>
  	</Dialog>
  	<Snackbar 
		open={snackbar.open} 
		autoHideDuration={6000} 
		onClose={() => setSnackbar({ ...snackbar, open: false })}
  	>
		<Alert severity={snackbar.severity} sx={{ width: '100%' }}>
	  	{snackbar.message}
		</Alert>
  	</Snackbar>
	</Grid>
	);
	};
	
  const calculateProfileCompletion = (profile) => {
    if (!profile) return 0; // Return 0 if profile is null or undefined
  
    const fields = [
      'name', 'email', 'phoneNumber', 'address', 'dateOfBirth',
      'department', 'position', 'hireDate', 'education', 'achievements',
      'certifications', 'socialLinks'
    ];
  
    const completedFields = fields.filter(field => {
      if (!profile.hasOwnProperty(field)) return false;
      
      if (Array.isArray(profile[field])) {
        return profile[field].length > 0;
      }
      if (typeof profile[field] === 'object' && profile[field] !== null) {
        return Object.values(profile[field]).some(value => value !== null && value !== undefined && value !== '');
      }
      return !!profile[field];
    });
  
    return Math.round((completedFields.length / fields.length) * 100);
  };
	
	export default UserProfile;
	