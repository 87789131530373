import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, Button, List, ListItem, ListItemText,
  CircularProgress, Box, TextField, Chip
} from '@mui/material';
import { fetchOnboardingData, addOnboardingTask, updateOnboardingTask } from '../services/api';

const OnboardingDashboard = ({ user }) => {
  const [onboardingData, setOnboardingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newTaskTitle, setNewTaskTitle] = useState('');

  useEffect(() => {
    loadOnboardingData();
  }, []);

  const loadOnboardingData = async () => {
    try {
      const data = await fetchOnboardingData();
      setOnboardingData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching onboarding data:', error);
      setLoading(false);
    }
  };

  const handleAddTask = async () => {
    if (!newTaskTitle.trim()) return;
    try {
      await addOnboardingTask({ title: newTaskTitle });
      setNewTaskTitle('');
      await loadOnboardingData();
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const handleUpdateTask = async (taskId, status) => {
    try {
      await updateOnboardingTask(taskId, { status });
      await loadOnboardingData();
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  const renderTasks = (tasks) => (
    <List>
      {tasks.map((task) => (
        <ListItem key={task._id}>
          <ListItemText primary={task.title} />
          <Chip 
            label={task.status} 
            color={task.status === 'Completed' ? 'success' : 'default'} 
            onClick={() => handleUpdateTask(task._id, task.status === 'Completed' ? 'Pending' : 'Completed')}
          />
        </ListItem>
      ))}
    </List>
  );

  const getTasks = () => {
    if (!onboardingData) return [];
    if (Array.isArray(onboardingData)) {
      return onboardingData.flatMap(employee => employee.tasks || []);
    }
    return onboardingData.tasks || [];
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Onboarding Dashboard</Typography>
      <Grid container spacing={3}>
        {user.role === 'employee' && onboardingData && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16 }}>
              <Typography variant="h6" gutterBottom>My Onboarding Tasks</Typography>
              {renderTasks(getTasks())}
            </Paper>
          </Grid>
        )}
        {(user.role === 'admin' || user.role === 'manager') && (
          <>
            <Grid item xs={12}>
              <Paper style={{ padding: 16 }}>
                <Typography variant="h6" gutterBottom>Add New Task</Typography>
                <TextField
                  fullWidth
                  value={newTaskTitle}
                  onChange={(e) => setNewTaskTitle(e.target.value)}
                  placeholder="New task title"
                />
                <Button onClick={handleAddTask} variant="contained" color="primary" style={{ marginTop: 16 }}>
                  Add Task
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 16 }}>
                <Typography variant="h6" gutterBottom>All Onboarding Tasks</Typography>
                {renderTasks(getTasks())}
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default OnboardingDashboard;