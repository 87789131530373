import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, IconButton,
  InputAdornment, Snackbar, Alert, TablePagination, TableSortLabel, Chip, Avatar, Box,
  Tabs, Tab, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Card, CardContent,
  CardActions, CardMedia, Tooltip, Fab
} from '@mui/material';
import { 
  Search, Add, Edit, Block, CheckCircle, Person, AttachMoney, Security, Work, Email, Phone,
  LocationOn, Cake, Wc, CalendarToday, ContactPhone, GridView as GridViewIcon, ViewList as ViewListIcon
} from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { getUsers, createUser, updateUser, deactivateUser, reactivateUser, sendEmail } from '../services/api';
import Carousel from 'react-material-ui-carousel';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .05)',
}));

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
	borderRadius: theme.shape.borderRadius * 5,
	transition: theme.transitions.create(['border-color', 'box-shadow']),
	'&:hover': {
	  backgroundColor: alpha(theme.palette.common.white, 0.95),
	},
	'&.Mui-focused': {
	  backgroundColor: alpha(theme.palette.common.white, 1),
	  boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
	},
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 5,
  textTransform: 'none',
  fontWeight: 600,
}));

const UserCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
	transform: 'translateY(-5px)',
	boxShadow: theme.shadows[8],
  },
}));

const CarouselBanner = () => {
  const items = [
	{
	  image: 'https://source.unsplash.com/random/1600x900/?office',
	  title: 'Welcome to User Management',
	  description: 'Efficiently manage your team members',
	},
	{
	  image: 'https://source.unsplash.com/random/1600x900/?team',
	  title: 'Empower Your Team',
	  description: 'Assign roles and permissions with ease',
	},
	{
	  image: 'https://source.unsplash.com/random/1600x900/?productivity',
	  title: 'Boost Productivity',
	  description: 'Streamline your HR processes',
	},
  ];

  return (
	<Carousel>
	  {items.map((item, index) => (
		<Paper key={index}>
		  <CardMedia
			component="img"
			height="300"
			image={item.image}
			alt={item.title}
		  />
		  <Box
			sx={{
			  position: 'absolute',
			  bottom: 0,
			  left: 0,
			  width: '100%',
			  bgcolor: 'rgba(0, 0, 0, 0.54)',
			  color: 'white',
			  padding: '20px',
			}}
		  >
			<Typography variant="h5">{item.title}</Typography>
			<Typography variant="body2">{item.description}</Typography>
		  </Box>
		</Paper>
	  ))}
	</Carousel>
  );
};

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [selectedTab, setSelectedTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [viewMode, setViewMode] = useState('grid');

  useEffect(() => {
	fetchUsers();
  }, []);

  useEffect(() => {
	const filtered = users.filter(user =>
	  user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
	  user.email.toLowerCase().includes(searchTerm.toLowerCase())
	);
	setFilteredUsers(filtered);
  }, [searchTerm, users]);

  const fetchUsers = async () => {
	try {
	  const response = await getUsers();
	  setUsers(response.data);
	  setFilteredUsers(response.data);
	} catch (error) {
	  console.error('Error fetching users:', error);
	  setSnackbar({ open: true, message: 'Failed to fetch users', severity: 'error' });
	}
  };

  const handleOpenDialog = (user = null) => {
	setSelectedUser(user);
	setOpenDialog(true);
  };

  const handleCloseDialog = () => {
	setSelectedUser(null);
	setOpenDialog(false);
  };

  const handleSaveUser = async (userData) => {
	try {
	  if (selectedUser) {
		await updateUser(selectedUser._id, userData);
		setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
	  } else {
		await createUser(userData);
		setSnackbar({ open: true, message: 'User created successfully', severity: 'success' });
	  }
	  fetchUsers();
	  handleCloseDialog();
	} catch (error) {
	  console.error('Error saving user:', error);
	  setSnackbar({ open: true, message: `Failed to save user: ${error.message}`, severity: 'error' });
	}
  };

  const handleToggleUserStatus = async (user) => {
	try {
	  if (user.isActive) {
		await deactivateUser(user.id);
		setSnackbar({ open: true, message: 'User deactivated successfully', severity: 'success' });
	  } else {
		await reactivateUser(user.id);
		setSnackbar({ open: true, message: 'User reactivated successfully', severity: 'success' });
	  }
	  fetchUsers();
	} catch (error) {
	  console.error('Error toggling user status:', error);
	  setSnackbar({ open: true, message: 'Failed to update user status', severity: 'error' });
	}
  };

  const handleChangePage = (event, newPage) => {
	setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
	setRowsPerPage(parseInt(event.target.value, 10));
	setPage(0);
  };

  const handleTabChange = (event, newValue) => {
	setSelectedTab(newValue);
  };

  const handleUserDetailsOpen = (user) => {
	setSelectedUserDetails(user);
	setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
	setDrawerOpen(false);
  };

  const handleRequestSort = (property) => {
	const isAsc = orderBy === property && order === 'asc';
	setOrder(isAsc ? 'desc' : 'asc');
	setOrderBy(property);
  };

  const handleSendEmail = async (user) => {
	try {
	  const subject = 'Hello from HR Management';
	  const content = `Dear ${user.name},\n\nThis is a test email from our HR Management system.\n\nBest regards,\nHR Team`;
	  
	  await sendEmail(user.email, subject, content);
	  setSnackbar({ open: true, message: 'Email sent successfully', severity: 'success' });
	} catch (error) {
	  console.error('Error sending email:', error);
	  setSnackbar({ open: true, message: 'Failed to send email', severity: 'error' });
	}
  };

  const sortedUsers = filteredUsers.sort((a, b) => {
	if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
	if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
	return 0;
  });

  return (
	<Grid container spacing={3}>
	  <Grid item xs={12}>
		<CarouselBanner />
	  </Grid>
	  <Grid item xs={12}>
		<Typography variant="h4" gutterBottom>
		  User Management
		</Typography>
	  </Grid>
	  <Grid item xs={12}>
		<StyledPaper>
		  <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
			<SearchField
			  variant="outlined"
			  placeholder="Search users"
			  InputProps={{
				startAdornment: (
				  <InputAdornment position="start">
					<Search />
				  </InputAdornment>
				),
			  }}
			  onChange={(e) => setSearchTerm(e.target.value)}
			/>
			<Box>
			  <Tooltip title="Grid View">
				<IconButton onClick={() => setViewMode('grid')}>
				  <GridViewIcon />
				</IconButton>
			  </Tooltip>
			  <Tooltip title="List View">
				<IconButton onClick={() => setViewMode('list')}>
				  <ViewListIcon />
				</IconButton>
			  </Tooltip>
			  <ActionButton
				variant="contained"
				color="primary"
				startIcon={<Add />}
				onClick={() => handleOpenDialog()}
			  >
				Add User
			  </ActionButton>
			</Box>
		  </Box>
		  <Tabs value={selectedTab} onChange={handleTabChange} centered>
			<Tab label="All Users" />
			<Tab label="Employees" />
			<Tab label="Managers" />
			<Tab label="Admins" />
		  </Tabs>
		  {viewMode === 'grid' ? (
			<Grid container spacing={3}>
			  {sortedUsers
				.filter(user => {
				  if (selectedTab === 0) return true;
				  if (selectedTab === 1) return user.role === 'employee';
				  if (selectedTab === 2) return user.role === 'manager';
				  if (selectedTab === 3) return user.role === 'admin';
				})
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				.map((user) => (
				  <Grid item xs={12} sm={6} md={4} key={user.id}>
					<UserCard>
					  <CardContent>
						<Box display="flex" alignItems="center" mb={2}>
						  <Avatar src={user.avatar} alt={user.name} sx={{ width: 60, height: 60, mr: 2 }} />
						  <Box>
							<Typography variant="h6">{user.name}</Typography>
							<Typography variant="body2" color="textSecondary">{user.email}</Typography>
						  </Box>
						</Box>
						<Chip 
						  label={user.role} 
						  color={user.role === 'admin' ? 'secondary' : user.role === 'manager' ? 'primary' : 'default'}
						  sx={{ mb: 1 }}
						/>
						<Chip 
						  label={user.isActive ? 'Active' : 'Inactive'} 
						  color={user.isActive ? 'success' : 'error'}
						  sx={{ ml: 1, mb: 1 }}
						/>
						<Typography variant="body2" color="textSecondary">
						  <Work sx={{ verticalAlign: 'middle', mr: 1 }} />
						  {user.position || 'Not specified'}
						</Typography>
						<Typography variant="body2" color="textSecondary">
						  <LocationOn sx={{ verticalAlign: 'middle', mr: 1 }} />
						  {user.department || 'Not specified'}
						</Typography>
					  </CardContent>
					  <CardActions>
						<Button size="small" startIcon={<Edit />} onClick={() => handleUserDetailsOpen(user)}>
						  Edit
						</Button>
						<Button size="small" startIcon={<Email />} onClick={() => handleSendEmail(user)}>
						  Email
						</Button>
						<Button
						  size="small"
						  startIcon={user.isActive ? <Block /> : <CheckCircle />}
						  onClick={() => handleToggleUserStatus(user)}
						>
						  {user.isActive ? 'Deactivate' : 'Activate'}
						</Button>
					  </CardActions>
					</UserCard>
				  </Grid>
				))}
			</Grid>
		  ) : (
			<TableContainer>
			  <Table>
				<TableHead>
				  <TableRow>
					<TableCell>
					  <TableSortLabel
						active={orderBy === 'name'}
						direction={orderBy === 'name' ? order : 'asc'}
						onClick={() => handleRequestSort('name')}
					  >
						Name
					  </TableSortLabel>
					</TableCell>
					<TableCell>
					  <TableSortLabel
						active={orderBy === 'email'}
						direction={orderBy === 'email' ? order : 'asc'}
						onClick={() => handleRequestSort('email')}
					  >
						Email
					  </TableSortLabel>
					</TableCell>
					<TableCell>
					  <TableSortLabel
						active={orderBy === 'role'}
						direction={orderBy === 'role' ? order : 'asc'}
						onClick={() => handleRequestSort('role')}
					  >
						Role
					  </TableSortLabel>
					</TableCell>
					<TableCell>Status</TableCell>
					<TableCell>Actions</TableCell>
				  </TableRow>
				</TableHead>
				<TableBody>
				  {sortedUsers
					.filter(user => {
					  if (selectedTab === 0) return true;
					  if (selectedTab === 1) return user.role === 'employee';
					  if (selectedTab === 2) return user.role === 'manager';
					  if (selectedTab === 3) return user.role === 'admin';
					})
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.map((user) => (
					  <TableRow key={user.id} hover>
						<TableCell>
						  <Box display="flex" alignItems="center">
							<Avatar src={user.avatar} alt={user.name} sx={{ mr: 2 }} />
							{user.name}
							</Box>
													</TableCell>
													<TableCell>{user.email}</TableCell>
													<TableCell>
													  <Chip 
														label={user.role} 
														color={user.role === 'admin' ? 'secondary' : user.role === 'manager' ? 'primary' : 'default'}
													  />
													</TableCell>
													<TableCell>
													  <Chip 
														label={user.isActive ? 'Active' : 'Inactive'} 
														color={user.isActive ? 'success' : 'error'}
													  />
													</TableCell>
													<TableCell>
													  <IconButton onClick={() => handleUserDetailsOpen(user)}>
														<Edit />
													  </IconButton>
													  <IconButton onClick={() => handleToggleUserStatus(user)}>
														{user.isActive ? <Block color="error" /> : <CheckCircle color="success" />}
													  </IconButton>
													  <IconButton onClick={() => handleSendEmail(user)}>
														<Email />
													  </IconButton>
													</TableCell>
												  </TableRow>
												))}
											</TableBody>
										  </Table>
										</TableContainer>
									  )}
									  <TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={filteredUsers.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									  />
									</StyledPaper>
								  </Grid>
								  <Dialog open={openDialog} onClose={handleCloseDialog}>
									<DialogTitle>{selectedUser ? 'Edit User' : 'Add User'}</DialogTitle>
									<DialogContent>
									  <UserForm user={selectedUser} onSave={handleSaveUser} onCancel={handleCloseDialog} />
									</DialogContent>
								  </Dialog>
								  <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
									<UserDetails user={selectedUserDetails} onClose={handleDrawerClose} />
								  </Drawer>
								  <Snackbar
									open={snackbar.open}
									autoHideDuration={6000}
									onClose={() => setSnackbar({ ...snackbar, open: false })}
								  >
									<Alert severity={snackbar.severity} sx={{ width: '100%' }}>
									  {snackbar.message}
									</Alert>
								  </Snackbar>
								  <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => handleOpenDialog()}>
									<Add />
								  </Fab>
								</Grid>
							  );
							};
							
							const UserDetails = ({ user, onClose }) => {
							  if (!user) return null;
							
							  return (
								<Box sx={{ width: 300, p: 2 }}>
								  <Typography variant="h6" gutterBottom>User Details</Typography>
								  <List>
									<ListItem>
									  <ListItemIcon><Person /></ListItemIcon>
									  <ListItemText primary="Name" secondary={user.name} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><Email /></ListItemIcon>
									  <ListItemText primary="Email" secondary={user.email} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><Work /></ListItemIcon>
									  <ListItemText primary="Role" secondary={user.role} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><LocationOn /></ListItemIcon>
									  <ListItemText primary="Department" secondary={user.department || 'Not specified'} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><Work /></ListItemIcon>
									  <ListItemText primary="Position" secondary={user.position || 'Not specified'} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><Phone /></ListItemIcon>
									  <ListItemText primary="Phone" secondary={user.phoneNumber || 'Not specified'} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><LocationOn /></ListItemIcon>
									  <ListItemText primary="Address" secondary={user.address || 'Not specified'} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><Cake /></ListItemIcon>
									  <ListItemText primary="Date of Birth" secondary={user.dateOfBirth || 'Not specified'} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><Wc /></ListItemIcon>
									  <ListItemText primary="Gender" secondary={user.gender || 'Not specified'} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><CalendarToday /></ListItemIcon>
									  <ListItemText primary="Hire Date" secondary={user.hireDate || 'Not specified'} />
									</ListItem>
									<ListItem>
									  <ListItemIcon><ContactPhone /></ListItemIcon>
									  <ListItemText 
										primary="Emergency Contact" 
										secondary={user.emergencyContact ? `${user.emergencyContact.name} (${user.emergencyContact.relationship})` : 'Not specified'} 
									  />
									</ListItem>
								  </List>
								  <Divider />
								  <Box mt={2}>
									<Button variant="outlined" onClick={onClose}>Close</Button>
								  </Box>
								</Box>
							  );
							};
							
							const UserForm = ({ user, onSave, onCancel }) => {
							  const [userData, setUserData] = useState(user || {
								name: '',
								email: '',
								password: '',
								role: 'employee',
								department: '',
								position: '',
								hireDate: '',
								phoneNumber: '',
								address: '',
								emergencyContact: {
								  name: '',
								  relationship: '',
								  phoneNumber: ''
								},
								dateOfBirth: '',
								gender: ''
							  });
							
							  const handleChange = (e) => {
								const { name, value } = e.target;
								if (name.startsWith('emergencyContact.')) {
								  const field = name.split('.')[1];
								  setUserData(prev => ({
									...prev,
									emergencyContact: {
									  ...prev.emergencyContact,
									  [field]: value
									}
								  }));
								} else {
								  setUserData({ ...userData, [name]: value });
								}
							  };
							
							  const handleSubmit = (e) => {
								e.preventDefault();
								onSave(userData);
							  };
							
							  return (
								<form onSubmit={handleSubmit}>
								  <TextField
									fullWidth
									margin="normal"
									name="name"
									label="Name"
									value={userData.name}
									onChange={handleChange}
									required
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="email"
									label="Email"
									type="email"
									value={userData.email}
									onChange={handleChange}
									required
								  />
								  {!user && (
									<TextField
									  fullWidth
									  margin="normal"
									  name="password"
									  label="Password"
									  type="password"
									  value={userData.password}
									  onChange={handleChange}
									  required
									/>
								  )}
								  <TextField
									fullWidth
									margin="normal"
									name="role"
									label="Role"
									select
									value={userData.role}
									onChange={handleChange}
									required
								  >
									<MenuItem value="employee">Employee</MenuItem>
									<MenuItem value="manager">Manager</MenuItem>
									<MenuItem value="admin">Admin</MenuItem>
								  </TextField>
								  <TextField
									fullWidth
									margin="normal"
									name="department"
									label="Department"
									value={userData.department}
									onChange={handleChange}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="position"
									label="Position"
									value={userData.position}
									onChange={handleChange}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="hireDate"
									label="Hire Date"
									type="date"
									value={userData.hireDate}
									onChange={handleChange}
									InputLabelProps={{ shrink: true }}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="phoneNumber"
									label="Phone Number"
									value={userData.phoneNumber}
									onChange={handleChange}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="address"
									label="Address"
									value={userData.address}
									onChange={handleChange}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="emergencyContact.name"
									label="Emergency Contact Name"
									value={userData.emergencyContact.name}
									onChange={handleChange}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="emergencyContact.relationship"
									label="Emergency Contact Relationship"
									value={userData.emergencyContact.relationship}
									onChange={handleChange}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="emergencyContact.phoneNumber"
									label="Emergency Contact Phone"
									value={userData.emergencyContact.phoneNumber}
									onChange={handleChange}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="dateOfBirth"
									label="Date of Birth"
									type="date"
									value={userData.dateOfBirth}
									onChange={handleChange}
									InputLabelProps={{ shrink: true }}
								  />
								  <TextField
									fullWidth
									margin="normal"
									name="gender"
									label="Gender"
									select
									value={userData.gender}
									onChange={handleChange}
									required
								  >
									<MenuItem value="male">Male</MenuItem>
									<MenuItem value="female">Female</MenuItem>
									<MenuItem value="other">Other</MenuItem>
								  </TextField>
								  <DialogActions>
									<Button onClick={onCancel}>Cancel</Button>
									<Button type="submit" variant="contained" color="primary">
									  {user ? 'Update' : 'Create'}
									</Button>
								  </DialogActions>
								</form>
							  );
							};
							
							export default UserManagement;