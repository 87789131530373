import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const NewJobDialog = ({ open, onClose, onSubmit }) => {
  const [jobData, setJobData] = useState({
    title: '',
    department: '',
    description: '',
    requiredSkills: '',
    experienceRequired: '',
    salaryMin: '',
    salaryMax: '',
  });

  const handleChange = (e) => {
    setJobData({ ...jobData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    onSubmit(jobData);
    setJobData({
      title: '',
      department: '',
      description: '',
      requiredSkills: '',
      experienceRequired: '',
      salaryMin: '',
      salaryMax: '',
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Post New Job</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="title"
          label="Job Title"
          type="text"
          fullWidth
          value={jobData.title}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="department"
          label="Department"
          type="text"
          fullWidth
          value={jobData.department}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="description"
          label="Job Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={jobData.description}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="requiredSkills"
          label="Required Skills (comma separated)"
          type="text"
          fullWidth
          value={jobData.requiredSkills}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="experienceRequired"
          label="Experience Required (years)"
          type="number"
          fullWidth
          value={jobData.experienceRequired}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="salaryMin"
          label="Minimum Salary"
          type="number"
          fullWidth
          value={jobData.salaryMin}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="salaryMax"
          label="Maximum Salary"
          type="number"
          fullWidth
          value={jobData.salaryMax}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewJobDialog;