// src/components/HolidayManagement.js
import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, Button, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Dialog,
  DialogTitle, DialogContent, DialogActions, CircularProgress,
  Snackbar, Alert, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getHolidays, createHoliday, getTenantInfo } from '../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const HolidayManagement = () => {
  const [holidays, setHolidays] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [holidayForm, setHolidayForm] = useState({
    name: '',
    date: '',
    description: '',
    type: 'company',
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [tenantInfo, setTenantInfo] = useState(null);

  useEffect(() => {
    fetchHolidays();
    fetchTenantInfo();
    }, []);

    const fetchHolidays = async () => {
      setLoading(true);
      try {
        const response = await getHolidays();
        setHolidays(response.data);
      } catch (error) {
        console.error('Error fetching holidays:', error);
        setSnackbar({ open: true, message: 'Failed to fetch holidays', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };
  
    const fetchTenantInfo = async () => {
      try {
        const response = await getTenantInfo();
        setTenantInfo(response.data);
      } catch (error) {
        console.error('Error fetching tenant info:', error);
        setSnackbar({ open: true, message: 'Failed to fetch tenant info', severity: 'error' });
      }
    };
  
  const handleOpenDialog = (holiday = null) => {
    setSelectedHoliday(holiday);
    setHolidayForm(holiday || {
      name: '',
      date: '',
      description: '',
      type: 'company',
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedHoliday(null);
  };

  const handleFormChange = (e) => {
    setHolidayForm({ ...holidayForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const holidayData = {
        ...holidayForm,
        country: tenantInfo ? tenantInfo.country : ''
      };
      
      if (selectedHoliday) {
        await createHoliday(holidayData); 
      setSnackbar({ open: true, message: 'Holiday updated successfully', severity: 'success' });
    } else {
      await createHoliday(holidayData);
      setSnackbar({ open: true, message: 'Holiday created successfully', severity: 'success' });
    }
    fetchHolidays();
    handleCloseDialog();
  } catch (error) {
    console.error('Error saving holiday:', error);
    setSnackbar({ open: true, message: 'Failed to save holiday', severity: 'error' });
  } finally {
    setLoading(false);
  }
};

const handleDeleteHoliday = (id) => {
  console.log('Delete holiday functionality not implemented');
  setSnackbar({ open: true, message: 'Delete holiday functionality not implemented', severity: 'warning' });
};

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Holiday Management</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog()}
            style={{ marginBottom: '1rem' }}
          >
            Add Holiday
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holidays.map((holiday) => (
                  <TableRow key={holiday._id}>
                    <TableCell>{holiday.name}</TableCell>
                    <TableCell>{new Date(holiday.date).toLocaleDateString()}</TableCell>
                    <TableCell>{holiday.type}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleOpenDialog(holiday)}>Edit</Button>
                      <Button onClick={() => handleDeleteHoliday(holiday._id)} color="secondary">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedHoliday ? 'Edit Holiday' : 'Add Holiday'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              name="name"
              label="Holiday Name"
              value={holidayForm.name}
              onChange={handleFormChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              name="date"
              label="Date"
              type="date"
              value={holidayForm.date}
              onChange={handleFormChange}
              InputLabelProps={{ shrink: true }}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              name="description"
              label="Description"
              value={holidayForm.description}
              onChange={handleFormChange}
              multiline
              rows={3}
            />
            <TextField
              fullWidth
              margin="normal"
              name="type"
              label="Type"
              select
              value={holidayForm.type}
              onChange={handleFormChange}
              required
            >
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="company">Company</MenuItem>
            </TextField>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default HolidayManagement;
