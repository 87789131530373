import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, CircularProgress, Box, Alert
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Add as AddIcon } from '@mui/icons-material';
import NewJobDialog from './NewJobDialog';
import { fetchRecruitmentData, createJob } from '../services/api';

const RecruitmentDashboard = () => {
  const [recruitmentData, setRecruitmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openNewJobDialog, setOpenNewJobDialog] = useState(false);

  useEffect(() => {
    fetchRecruitmentData()
      .then(data => {
        setRecruitmentData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching recruitment data:', error);
        setError('Failed to fetch recruitment data. Please try again later.');
        setLoading(false);
      });
  }, []);

  const handleOpenNewJobDialog = () => {
    setOpenNewJobDialog(true);
  };

  const handleCloseNewJobDialog = () => {
    setOpenNewJobDialog(false);
  };

  const handleCreateJob = (jobData) => {
    createJob(jobData)
      .then(() => {
        return fetchRecruitmentData();
      })
      .then(data => {
        setRecruitmentData(data);
        handleCloseNewJobDialog();
      })
      .catch(error => {
        console.error('Error creating job:', error);
        setError('Failed to create job. Please try again.');
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!recruitmentData || !recruitmentData.jobListings) {
    return <Alert severity="info">No recruitment data available.</Alert>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Recruitment Dashboard</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Job Listings Overview</Typography>
          {recruitmentData.jobListings.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Job Title</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Applicants</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recruitmentData.jobListings.map((job) => (
                    <TableRow key={job.id}>
                      <TableCell>{job.title}</TableCell>
                      <TableCell>{job.department}</TableCell>
                      <TableCell>{job.status}</TableCell>
                      <TableCell>{job.applicants}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No job listings available.</Typography>
          )}
          <Box mt={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenNewJobDialog}
            >
              Post New Job
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Application Statistics</Typography>
          {recruitmentData.applicationStats.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={recruitmentData.applicationStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="stage" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Typography>No application statistics available.</Typography>
          )}
        </Paper>
      </Grid>
      <NewJobDialog
        open={openNewJobDialog}
        onClose={handleCloseNewJobDialog}
        onSubmit={handleCreateJob}
      />
    </Grid>
  );
};

export default RecruitmentDashboard;
