// src/components/Sidebar.js
import React, { useState } from 'react';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText,
  Collapse, Divider, Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Dashboard, People, Event, Assignment, Timeline, TrendingUp,
  GroupAdd, Description, Assessment, AttachMoney, Settings, 
  ExpandLess, ExpandMore, CalendarToday, Person, 
  Layers, BarChart, Work, Headset, BusinessCenter, School, Cake,
  EmojiPeople, LocalHospital, Gavel, 
  LiveHelp, Security, AccessTime
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Sidebar = ({ userRole }) => {
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState({});

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setMenuOpen({}); 
  };

  const handleClick = (item) => {
    setMenuOpen(prevState => ({ ...prevState, [item]: !prevState[item] }));
  };

  const menuItems = [
    { text: 'Dashboard', icon: <Dashboard />, path: '/dashboard' },
    { text: 'Profile', icon: <Person />, path: '/userprofile' },
    {
      text: 'Employee Management',
      icon: <People />,
      subitems: [
      { text: 'User Management', icon: <Assignment />, path: '/user-management', roles: ['admin', 'superadmin'] },
      { text: 'Leave Management', icon: <Event />, path: '/leave' },
      { text: 'Holiday Management', icon: <CalendarToday />, path: '/holiday-management', roles: ['admin', 'superadmin'] },
      { text: 'Attendance', icon: <AccessTime />, path: '/attendance' },
      { text: 'Performance', icon: <TrendingUp />, path: '/performance' },
      ],
    },
    {
      text: 'HR Operations',
      icon: <BusinessCenter />,
      subitems: [
      { text: 'Recruitment', icon: <GroupAdd />, path: '/recruitment' },
      { text: 'Onboarding', icon: <EmojiPeople />, path: '/onboarding' },
      { text: 'Training', icon: <School />, path: '/training' },
      { text: 'Employee Relations', icon: <People />, path: '/employee-relations' },
      ],
    },
    {
      text: 'Payroll & Benefits',
      icon: <AttachMoney />,
      subitems: [
      { text: 'Payroll', icon: <AttachMoney />, path: '/payroll' },
      { text: 'Benefits Administration', icon: <LocalHospital />, path: '/benefits' },
      { text: 'Compensation Planning', icon: <TrendingUp />, path: '/compensation' },
      ],
    },
    {
      text: 'Reports & Analytics',
      icon: <Assessment />,
      subitems: [
      { text: 'Attendance Reports', icon: <BarChart />, path: '/reports/attendance' },
      { text: 'Performance Reports', icon: <TrendingUp />, path: '/reports/performance' },
      { text: 'Payroll Reports', icon: <AttachMoney />, path: '/reports/payroll' },
      { text: 'Custom Reports', icon: <Layers />, path: '/reports/custom' },
      ],
    },
    { text: 'Documents', icon: <Description />, path: '/documents' },
    {
      text: 'Company',
      icon: <BusinessCenter />,
      subitems: [
      { text: 'Company Policies', icon: <Gavel />, path: '/company-policies' },
      { text: 'Organizational Chart', icon: <People />, path: '/org-chart' },
      { text: 'Company Calendar', icon: <Cake />, path: '/company-calendar' },
      ],
    },
    { text: 'Support', icon: <Headset />, path: '/support' },
    { text: 'Settings', icon: <Settings />, path: '/settings' },
    { text: 'Help Center', icon: <LiveHelp />, path: '/help-center' },
    { text: 'Security', icon: <Security />, path: '/security', roles: ['admin', 'superadmin'] },
    ];

  const renderMenuItem = (item) => {
    if (item.roles && !item.roles.includes(userRole)) {
      return null;
    }

    if (item.subitems) {
      return (
        <React.Fragment key={item.text}>
          <ListItem button onClick={() => handleClick(item.text)}>
            <ListItemIcon>
              <Tooltip title={item.text} placement="right" arrow disabled={open}>
                <span>{item.icon}</span>
              </Tooltip>
            </ListItemIcon>
            {open && <ListItemText primary={item.text} />}
            {open && (menuOpen[item.text] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open && menuOpen[item.text]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subitems.map((subitem) => renderMenuItem({ ...subitem, nested: true }))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }

    return (
      <ListItem 
        button 
        key={item.text} 
        component={Link} 
        to={item.path}
        sx={{ pl: item.nested ? 4 : 2 }}
      >
        <ListItemIcon>
          <Tooltip title={item.text} placement="right" arrow disabled={open}>
            <span>{item.icon}</span>
          </Tooltip>
        </ListItemIcon>
        {open && <ListItemText primary={item.text} />}
      </ListItem>
    );
  };

  return (
    <StyledDrawer 
      variant="permanent" 
      open={open}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Divider />
      <List>
        {menuItems.map(renderMenuItem)}
      </List>
    </StyledDrawer>
  );
};

export default Sidebar;