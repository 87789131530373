// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import theme from './styles/theme';
import GlobalStyle from './styles/GlobalStyle';
import Dashboard from './components/Dashboard';
import LeaveManagement from './components/LeaveManagement';
import UserProfile from './components/UserProfile';
import UserManagement from './components/UserManagement';
import TeamManagement from './components/TeamManagement';
import Reports from './components/Reports';
import TenantManagement from './components/TenantManagement';
import HolidayManagement from './components/HolidayManagement';
import SplashScreen from './components/SplashScreen';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import AuthPage from './components/AuthPage';
import RecruitmentDashboard from './components/RecruitmentDashboard';
import OnboardingDashboard from './components/OnboardingDashboard';
import TrainingDashboard from './components/TrainingDashboard';
import EmployeeRelationsDashboard from './components/EmployeeRelationsDashboard';
import AttendanceDashboard from './components/AttendanceDashboard';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    if (token && storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {GlobalStyle}
      <Router>
        {user ? (
          <Box sx={{ display: 'flex' }}>
          <Header user={user} onLogout={handleLogout} onToggleSidebar={toggleSidebar} />
          <Sidebar open={sidebarOpen} onToggle={toggleSidebar} userRole={user.role} />
          <Box component="main" sx={{ 
            flexGrow: 1, 
            p: 0,
            width: { sm: `calc(100% - ${sidebarOpen ? 240 : 64}px)` },
            ml: { sm: `${theme.spacing(7) + 1}px` },
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}>
              <Routes>
                <Route path="/dashboard" element={<Dashboard user={user} />} />
                <Route path="/leave" element={<LeaveManagement user={user} />} />
                <Route path="/userprofile" element={<UserProfile user={user} />} />
                <Route 
                  path="/team" 
                  element={user.role === 'manager' || user.role === 'admin' || user.role === 'superadmin' ? 
                    <TeamManagement user={user} /> : <Navigate to="/dashboard" />} 
                />
                <Route 
                  path="/user-management" 
                  element={user.role === 'admin' || user.role === 'superadmin' ? 
                    <UserManagement user={user} /> : <Navigate to="/dashboard" />} 
                />
                <Route 
                  path="/reports" 
                  element={user.role === 'admin' || user.role === 'superadmin' ? 
                    <Reports user={user} /> : <Navigate to="/dashboard" />} 
                />
                <Route 
                  path="/holiday-management" 
                  element={user.role === 'admin' || user.role === 'superadmin' ? 
                  <HolidayManagement user={user} /> : <Navigate to="/dashboard" />} 
                />
                <Route 
                  path="/tenants" 
                  element={user.role === 'superadmin' ? 
                    <TenantManagement user={user} /> : <Navigate to="/dashboard" />} 
                />
                <Route 
                  path="/recruitment" 
                  element={user.role === 'manager' || user.role === 'admin' || user.role === 'superadmin' ? 
                    <RecruitmentDashboard user={user} /> : <Navigate to="/dashboard" />} 
                />
                <Route 
                  path="/onboarding" 
                  element={user.role === 'manager' || user.role === 'admin' || user.role === 'superadmin' ? 
                    <OnboardingDashboard user={user} /> : <Navigate to="/dashboard" />} 
                />
                <Route 
                  path="/training" 
                  element={<TrainingDashboard user={user} />} 
                />
                <Route 
                  path="/attendance" 
                  element={<AttendanceDashboard user={user} />} 
                />
                <Route 
                  path="/employee-relations" 
                  element={user.role === 'manager' || user.role === 'admin' || user.role === 'superadmin' ? 
                    <EmployeeRelationsDashboard user={user} /> : <Navigate to="/dashboard" />} 
                />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </Box>
          </Box>
        ) : (
          <Routes>
            <Route path="/auth" element={<AuthPage setUser={setUser} />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </Routes>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;