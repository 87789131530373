import React, { useState, useEffect } from 'react';
import { 
  Grid, Paper, Typography, Button, TextField, Select, MenuItem, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Dialog, DialogTitle, DialogContent, DialogActions,
  CircularProgress, Snackbar, Alert, Switch, FormControlLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
	getLeaveBalances, createLeaveRequest, approveLeave, getLeaveRequests,
	createLeaveType, getLeaveTypes
  } from '../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const LeaveManagement = ({ user }) => {
  const [leaveBalances, setLeaveBalances] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [newLeaveRequest, setNewLeaveRequest] = useState({
	startDate: '',
	endDate: '',
	leaveType: '',
	reason: '',
  });
  const [newLeaveType, setNewLeaveType] = useState({
	name: '',
	defaultAllowance: 0,
	accrual: 'yearly',
	accrualAmount: 0,
	maxAccrual: 0,
	carryOver: false,
	carryOverAmount: 0,
	isUnlimited: false,
	isPaidLeave: true,
  });
  const [openDialog, setOpenDialog] = useState(false);
  //const [setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
	fetchLeaveBalances();
	fetchLeaveTypes();
	fetchLeaveRequests();
  }, []);

  const fetchLeaveBalances = async () => {
	setLoading(true);
	try {
	  const response = await getLeaveBalances();
	  console.log('Leave balances:', response.data);
	  setLeaveBalances(response.data);
	} catch (error) {
	  console.error('Error fetching leave balances:', error);
	  setSnackbar({ open: true, message: 'Failed to fetch leave balances', severity: 'error' });
	} finally {
	  setLoading(false);
	}
  };

  const fetchLeaveTypes = async () => {
	setLoading(true);
	try {
	  const response = await getLeaveTypes();
	  console.log('Leave types:', response.data);
	  setLeaveTypes(response.data);
	} catch (error) {
	  console.error('Error fetching leave types:', error);
	  setSnackbar({ open: true, message: 'Failed to fetch leave types', severity: 'error' });
	} finally {
	  setLoading(false);
	}
  };

const fetchLeaveRequests = async () => {
	setLoading(true);
	try {
	  const response = await getLeaveRequests();
	  console.log('Leave requests:', response.data);
	  setLeaveRequests(response.data);
	} catch (error) {
	  console.error('Error fetching leave requests:', error);
	  setSnackbar({ open: true, message: 'Failed to fetch leave requests', severity: 'error' });
	} finally {
	  setLoading(false);
	}
  };

  const handleApplyLeave = async (e) => {
	e.preventDefault();
	setLoading(true);
	try {
	  await createLeaveRequest(newLeaveRequest);
	  setNewLeaveRequest({ startDate: '', endDate: '', leaveType: '', reason: '' });
	  fetchLeaveBalances();
	  fetchLeaveRequests();
	  setSnackbar({ open: true, message: 'Leave request submitted successfully', severity: 'success' });
	} catch (error) {
	  console.error('Error applying for leave:', error.response?.data || error);
	  const errorMessage = error.response?.data?.message || 'Failed to submit leave request';
	  setSnackbar({ open: true, message: errorMessage, severity: 'error' });
	} finally {
	  setLoading(false);
	}
  };

  const handleApproveLeave = async (leaveId) => {
	setLoading(true);
	try {
	  await approveLeave(leaveId, 'approved');
	  fetchLeaveRequests();
	  setSnackbar({ open: true, message: 'Leave request approved', severity: 'success' });
	} catch (error) {
	  console.error('Error approving leave:', error);
	  setSnackbar({ open: true, message: 'Failed to approve leave request', severity: 'error' });
	} finally {
	  setLoading(false);
	}
  };

  const handleAddLeaveType = async () => {
	setLoading(true);
	try {
	  await createLeaveType(newLeaveType);
	  setNewLeaveType({
		name: '',
		defaultAllowance: 0,
		accrual: 'yearly',
		accrualAmount: 0,
		maxAccrual: 0,
		carryOver: false,
		carryOverAmount: 0,
		isUnlimited: false,
		isPaidLeave: true,
	  });
	  fetchLeaveTypes();
	  setOpenDialog(false);
	  setSnackbar({ open: true, message: 'New leave type added successfully', severity: 'success' });
	} catch (error) {
	  console.error('Error adding leave type:', error);
	  setSnackbar({ open: true, message: `Failed to add new leave type: ${error.message}`, severity: 'error' });
	} finally {
	  setLoading(false);
	}
  };

  const handleLeaveTypeChange = (event) => {
	const { name, value, checked } = event.target;
	setNewLeaveType(prev => ({
	  ...prev,
	  [name]: name === 'carryOver' || name === 'isUnlimited' || name === 'isPaidLeave' ? checked : value
	}));
  };

  //const handleFileChange = (event) => {
	//setFile(event.target.files[0]);
  //};

  

  return (
	<Grid container spacing={3}>
	  <Grid item xs={12}>
		<Typography variant="h4">Leave Management</Typography>
	  </Grid>
	  
	  {/* Leave Balances */}
	  <Grid item xs={12}>
		<StyledPaper>
		  <Typography variant="h6">Leave Balances</Typography>
		  {loading ? (
			<CircularProgress />
		  ) : (
			leaveBalances.map((balance) => (
			  <Typography key={balance.leaveType._id}>
				{balance.leaveType?.name || 'Unknown'}: {balance.balance} days (Used: {balance.used} days)
			  </Typography>
			))
		  )}
		</StyledPaper>
	  </Grid>

	  {/* Apply for Leave */}
	  <Grid item xs={12} md={6}>
		<StyledPaper>
		  <Typography variant="h6">Apply for Leave</Typography>
		  <form onSubmit={handleApplyLeave}>
			<TextField
			  fullWidth
			  label="Start Date"
			  type="date"
			  value={newLeaveRequest.startDate}
			  onChange={(e) => setNewLeaveRequest({...newLeaveRequest, startDate: e.target.value})}
			  InputLabelProps={{ shrink: true }}
			  margin="normal"
			/>
			<TextField
			  fullWidth
			  label="End Date"
			  type="date"
			  value={newLeaveRequest.endDate}
			  onChange={(e) => setNewLeaveRequest({...newLeaveRequest, endDate: e.target.value})}
			  InputLabelProps={{ shrink: true }}
			  margin="normal"
			/>
			<Select
			  fullWidth
			  value={newLeaveRequest.leaveType}
			  onChange={(e) => setNewLeaveRequest({...newLeaveRequest, leaveType: e.target.value})}
			  margin="normal"
			>
			  {leaveTypes.map((type) => (
				<MenuItem key={type._id} value={type._id}>{type.name || 'Unknown'}</MenuItem>
			  ))}
			</Select>
			<TextField
			  fullWidth
			  label="Reason"
			  value={newLeaveRequest.reason}
			  onChange={(e) => setNewLeaveRequest({...newLeaveRequest, reason: e.target.value})}
			  margin="normal"
			/>
			<Button type="submit" variant="contained" color="primary" disabled={loading}>
			  {loading ? <CircularProgress size={24} /> : 'Apply for Leave'}
			</Button>
		  </form>
		</StyledPaper>
	  </Grid>

	  {/* Leave Requests (for managers) */}
	  {(user.role === 'manager' || user.role === 'admin') && (
		<Grid item xs={12} md={6}>
		  <StyledPaper>
			<Typography variant="h6">Leave Requests</Typography>
			{loading ? (
			  <CircularProgress />
			) : (
			  <TableContainer>
				<Table>
				  <TableHead>
					<TableRow>
					  <TableCell>Employee</TableCell>
					  <TableCell>Start Date</TableCell>
					  <TableCell>End Date</TableCell>
					  <TableCell>Type</TableCell>
					  <TableCell>Status</TableCell>
					  <TableCell>Action</TableCell>
					</TableRow>
				  </TableHead>
				  <TableBody>
					{leaveRequests.map((request) => (
					  <TableRow key={request._id}>
						<TableCell>{request.user?.name || 'Unknown'}</TableCell>
						<TableCell>{new Date(request.startDate).toLocaleDateString()}</TableCell>
						<TableCell>{new Date(request.endDate).toLocaleDateString()}</TableCell>
						<TableCell>{request.type?.name || 'Unknown'}</TableCell>
						<TableCell>{request.status}</TableCell>
						<TableCell>
						  {request.status === 'pending' && (
							<Button onClick={() => handleApproveLeave(request._id)} disabled={loading}>
							  {loading ? <CircularProgress size={24} /> : 'Approve'}
							</Button>
						  )}
						</TableCell>
					  </TableRow>
					))}
				  </TableBody>
				</Table>
			  </TableContainer>
			)}
		  </StyledPaper>
		</Grid>
	  )}

	  {/* Admin Functions */}
	  {user.role === 'admin' && (
		<Grid item xs={12}>
		  <StyledPaper>
			<Typography variant="h6">Admin Functions</Typography>
			<Button onClick={() => setOpenDialog(true)} variant="contained" color="primary" disabled={loading}>
			  Add Leave Type
			</Button>
			<input
			  accept=".csv"
			  style={{ display: 'none' }}
			  id="raised-button-file"
			  type="file"
			  //onChange={handleFileChange}
			/>
			{/*
			<label htmlFor="raised-button-file">
			  <Button variant="contained" component="span" disabled={loading}>
				Select CSV File
			  </Button>
			</label>
			{file && (
			  <Button onClick={handleUploadEmployees} variant="contained" color="primary" disabled={loading}>
				{loading ? <CircularProgress size={24} /> : 'Upload Employees'}
			  </Button>
			)}
			*/}
		  </StyledPaper>
		</Grid>
	  )}

	  {/* Add Leave Type Dialog */}
	  <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
		<DialogTitle>Add New Leave Type</DialogTitle>
		<DialogContent>
		  <Grid container spacing={2}>
			<Grid item xs={12}>
			  <TextField
				autoFocus
				margin="dense"
				name="name"
				label="Leave Type Name"
				fullWidth
				value={newLeaveType.name}
				onChange={handleLeaveTypeChange}
			  />
			</Grid>
			<Grid item xs={12} sm={6}>
			  <TextField
				margin="dense"
				name="defaultAllowance"
				label="Default Allowance"
				type="number"
				fullWidth
				value={newLeaveType.defaultAllowance}
				onChange={handleLeaveTypeChange}
			  />
			</Grid>
			<Grid item xs={12} sm={6}>
			  <Select
				fullWidth
				name="accrual"
				value={newLeaveType.accrual}
				onChange={handleLeaveTypeChange}
				margin="dense"
			  >
				<MenuItem value="monthly">Monthly</MenuItem>
				<MenuItem value="yearly">Yearly</MenuItem>
				<MenuItem value="none">None</MenuItem>
			  </Select>
			</Grid>
			<Grid item xs={12} sm={6}>
			  <TextField
				margin="dense"
				name="accrualAmount"
				label="Accrual Amount"
				type="number"
				fullWidth
				value={newLeaveType.accrualAmount}
				onChange={handleLeaveTypeChange}
			  />
			</Grid>
			<Grid item xs={12} sm={6}>
			  <TextField
				margin="dense"
				name="maxAccrual"
				label="Max Accrual"
				type="number"
				fullWidth
				value={newLeaveType.maxAccrual}
				onChange={handleLeaveTypeChange}
			  />
			</Grid>
			<Grid item xs={12} sm={6}>
			  <FormControlLabel
				control={
				  <Switch
					checked={newLeaveType.carryOver}
					onChange={handleLeaveTypeChange}
					name="carryOver"
				  />
				}
				label="Allow Carry Over"
			  />
			</Grid>
			{newLeaveType.carryOver && (
			  <Grid item xs={12} sm={6}>
				<TextField
				  margin="dense"
				  name="carryOverAmount"
				  label="Carry Over Amount"
				  type="number"
				  fullWidth
				  value={newLeaveType.carryOverAmount}
				  onChange={handleLeaveTypeChange}
				/>
			  </Grid>
			)}
			<Grid item xs={12} sm={6}>
			  <FormControlLabel
				control={
				  <Switch
					checked={newLeaveType.isUnlimited}
					onChange={handleLeaveTypeChange}
					name="isUnlimited"
				  />
				}
				label="Unlimited Leave"
			  />
			</Grid>
			<Grid item xs={12} sm={6}>
			  <FormControlLabel
				control={
				  <Switch
					checked={newLeaveType.isPaidLeave}
					onChange={handleLeaveTypeChange}
					name="isPaidLeave"
				  />
				}
				label="Paid Leave"
			  />
			</Grid>
		  </Grid>
		</DialogContent>
		<DialogActions>
		  <Button onClick={() => setOpenDialog(false)} disabled={loading}>Cancel</Button>
		  <Button onClick={handleAddLeaveType} color="primary" disabled={loading}>
			{loading ? <CircularProgress size={24} /> : 'Add'}
		  </Button>
		</DialogActions>
	 </Dialog>
	 
	 {/* Snackbar for notifications */}
		   <Snackbar
			 open={snackbar.open}
			 autoHideDuration={6000}
			 onClose={() => setSnackbar({ ...snackbar, open: false })}
		   >
			 <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
			   {snackbar.message}
			 </Alert>
		   </Snackbar>
		 </Grid>
	   );
	 };
	 
	 export default LeaveManagement;