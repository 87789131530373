import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Alert, CircularProgress } from '@mui/material';
import { Login, Logout } from '@mui/icons-material';
import { checkIn, checkOut, fetchAttendanceData } from '../services/api';

const DigitalClock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Typography variant="h3" component="div" sx={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
      {time.toLocaleTimeString()}
    </Typography>
  );
};

const AttendanceControl = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attendanceData, setAttendanceData] = useState(null);

  useEffect(() => {
    fetchAttendanceStatus();
  }, []);

  const fetchAttendanceStatus = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetchAttendanceData();
      setAttendanceData(response);
    } catch (error) {
      console.error('Error fetching attendance status:', error);
      setError('Failed to fetch attendance status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClockInOut = async () => {
    setLoading(true);
    setError(null);

    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      
      if (attendanceData && attendanceData.is_clocked_in) {
        await checkOut(latitude, longitude);
      } else {
        await checkIn(latitude, longitude);
      }

      await fetchAttendanceStatus(); 
    } catch (error) {
      console.error('Error during clock in/out:', error);
      setError(error.message || 'An unknown error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by this browser'));
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          timeout: 10000,
          maximumAge: 60000,
          enableHighAccuracy: true
        });
      }
    });
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h6" gutterBottom>Attendance Control</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <DigitalClock />
        {attendanceData && (
          <Button
            variant="contained"
            color={attendanceData.is_clocked_in ? "secondary" : "primary"}
            startIcon={attendanceData.is_clocked_in ? <Logout /> : <Login />}
            onClick={handleClockInOut}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : (attendanceData.is_clocked_in ? "Clock Out" : "Clock In")}
          </Button>
        )}
      </Box>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        Current Status: {attendanceData ? (attendanceData.is_clocked_in ? "Clocked In" : "Clocked Out") : "Loading..."}
      </Typography>
    </Box>
  );
};

export default AttendanceControl;