// Dashboard.js
import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, CircularProgress, Box, Card, CardContent,
  CardHeader, Avatar, IconButton, Tooltip, Button, Divider, Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Assessment, Work, Event, AttachMoney, Group,
  CalendarToday, TrendingUp, Notifications
} from '@mui/icons-material';
import { Pie, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import { fetchDashboardData, fetchAttendanceData } from '../services/api';
import AttendanceControl from './AttendanceControl';

ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[10],
    transform: 'translateY(-5px)',
  },
}));

const DashboardCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[10],
    transform: 'translateY(-5px)',
  },
}));

const Dashboard = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    teamSize: 0,
    upcomingEvents: 0,
    departmentBudget: 0,
    completedProjects: 0,
    totalProjects: 0,
    teamAttendance: { present: 0, absent: 0, 'half-day': 0, late: 0, 'early-leave': 0 },
    leaveBalance: {},
    recentActivities: [],
    performanceData: []
  });
  const [isClockedIn, setIsClockedIn] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [dashboardResponse, attendanceResponse] = await Promise.all([
        fetchDashboardData(),
        fetchAttendanceData()
      ]);
      console.log('Dashboard Response:', dashboardResponse);
      console.log('Attendance Response:', attendanceResponse);
      setDashboardData(prevData => ({
        ...prevData,
        ...dashboardResponse.data,
        teamAttendance: attendanceResponse
      }));
      setIsClockedIn(attendanceResponse.isClockedIn || false);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;

  const attendanceChartData = {
    labels: ['Present', 'Absent', 'Half-day', 'Late', 'Early Leave'],
    datasets: [{
      data: [
        dashboardData.teamAttendance.present || 0,
        dashboardData.teamAttendance.absent || 0,
        dashboardData.teamAttendance['half-day'] || 0,
        dashboardData.teamAttendance.late || 0,
        dashboardData.teamAttendance['early-leave'] || 0
      ],
      backgroundColor: ['#4CAF50', '#F44336', '#FFC107', '#FF9800', '#2196F3'],
    }]
  };

  const performanceChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [{
      label: 'Performance Score',
      data: dashboardData.performanceData || [65, 70, 80, 75, 85, 90],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Welcome, {user.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Here's an overview of your department's performance and key metrics.
        </Typography>
      </Grid>

      {/* Clock In/Out Section */}
      <Grid item xs={12}>
        <AttendanceControl 
          isClockedIn={isClockedIn}
          onStatusChange={fetchData}
        />
      </Grid>

      {/* Quick Stats Section */}
      <Grid item xs={12} md={3}>
        <DashboardCard>
          <CardHeader 
            avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><Group /></Avatar>}
            title="Team Size"
            subheader={dashboardData.teamSize}
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3}>
        <DashboardCard>
          <CardHeader 
            avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}><Event /></Avatar>}
            title="Upcoming Events"
            subheader={dashboardData.upcomingEvents}
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3}>
        <DashboardCard>
          <CardHeader 
            avatar={<Avatar sx={{ bgcolor: 'success.main' }}><AttachMoney /></Avatar>}
            title="Department Budget"
            subheader={`$${dashboardData.departmentBudget.toLocaleString()}`}
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3}>
        <DashboardCard>
          <CardHeader 
            avatar={<Avatar sx={{ bgcolor: 'warning.main' }}><Assessment /></Avatar>}
            title="Projects"
            subheader={`${dashboardData.completedProjects} / ${dashboardData.totalProjects}`}
          />
        </DashboardCard>
      </Grid>

      {/* Attendance Chart */}
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>Team Attendance</Typography>
          <Box height={300}>
            <Pie data={attendanceChartData} options={{ maintainAspectRatio: false }} />
          </Box>
        </StyledPaper>
      </Grid>

      {/* Performance Chart */}
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>Department Performance</Typography>
          <Box height={300}>
            <Line data={performanceChartData} options={{ maintainAspectRatio: false }} />
          </Box>
        </StyledPaper>
      </Grid>

      {/* Leave Balance Summary */}
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>Leave Balance</Typography>
          <Grid container spacing={2}>
            {Object.entries(dashboardData.leaveBalance).map(([type, balance]) => (
              <Grid item xs={4} key={type}>
                <Tooltip title={`${type} Leave`}>
                  <Box textAlign="center">
                    <CircularProgress 
                      variant="determinate" 
                      value={(balance / 20) * 100} 
                      size={80} 
                      thickness={4}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {type}: {balance}
                    </Typography>
                  </Box>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </StyledPaper>
      </Grid>

      {/* Recent Activities */}
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>Recent Activities</Typography>
          {dashboardData.recentActivities.map((activity, index) => (
            <Box key={index} mb={2}>
              <Typography variant="body1">{activity.description}</Typography>
              <Typography variant="caption" color="textSecondary">
                {new Date(activity.timestamp).toLocaleString()}
              </Typography>
              {index < dashboardData.recentActivities.length - 1 && <Divider sx={{ my: 1 }} />}
            </Box>
          ))}
        </StyledPaper>
      </Grid>

      {/* Quick Actions */}
      <Grid item xs={12}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>Quick Actions</Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" startIcon={<Event />}>
                Apply for Leave
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" startIcon={<Work />}>
                Submit Timesheet
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" startIcon={<Assessment />}>
                View Reports
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" startIcon={<Group />}>
                Team Directory
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default Dashboard;