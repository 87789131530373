// src/components/UpgradeDialog.js
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { upgradeTenant } from '../services/api';

const UpgradeDialog = ({ open, onClose, tenant, onUpgrade }) => {
  const [selectedPlan, setSelectedPlan] = useState('basic');

  const handleUpgrade = async () => {
    try {
      await upgradeTenant(tenant.id, selectedPlan);
      onUpgrade();
      onClose();
    } catch (error) {
      console.error('Error upgrading tenant:', error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upgrade Your Account</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Choose a plan to upgrade your account:
        </Typography>
        <RadioGroup
          aria-label="subscription-plan"
          name="subscription-plan"
          value={selectedPlan}
          onChange={(e) => setSelectedPlan(e.target.value)}
        >
          <FormControlLabel value="basic" control={<Radio />} label="Basic Plan - $9.99/month" />
          <FormControlLabel value="pro" control={<Radio />} label="Pro Plan - $19.99/month" />
          <FormControlLabel value="enterprise" control={<Radio />} label="Enterprise Plan - $49.99/month" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpgrade} color="primary" variant="contained">
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeDialog;