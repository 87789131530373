// src/components/TenantManagement.js
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
  Alert,
  TablePagination,
} from '@mui/material';
import { Add, Edit, Settings } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getTenants, createTenant, updateTenant, upgradeTenant } from '../services/api';
import UpgradeDialog from './UpgradeDialog';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const TenantManagement = () => {
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchTenants();
  }, []);

  const fetchTenants = async () => {
    try {
      const response = await getTenants();
      setTenants(response.data);
    } catch (error) {
      console.error('Error fetching tenants:', error);
      setSnackbar({ open: true, message: 'Failed to fetch tenants', severity: 'error' });
    }
  };

  const handleOpenDialog = (tenant = null) => {
    setSelectedTenant(tenant);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedTenant(null);
    setOpenDialog(false);
  };

  const handleOpenSettingsDialog = (tenant) => {
    setSelectedTenant(tenant);
    setOpenSettingsDialog(true);
  };

  const handleCloseSettingsDialog = () => {
    setSelectedTenant(null);
    setOpenSettingsDialog(false);
  };

  const handleOpenUpgradeDialog = (tenant) => {
    setSelectedTenant(tenant);
    setOpenUpgradeDialog(true);
  };

  const handleCloseUpgradeDialog = () => {
    setSelectedTenant(null);
    setOpenUpgradeDialog(false);
  };

  const handleSaveTenant = async (tenantData) => {
    try {
      if (selectedTenant) {
        await updateTenant(selectedTenant.id, tenantData);
        setSnackbar({ open: true, message: 'Tenant updated successfully', severity: 'success' });
      } else {
        await createTenant(tenantData);
        setSnackbar({ open: true, message: 'Tenant created successfully', severity: 'success' });
      }
      fetchTenants();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving tenant:', error);
      setSnackbar({ open: true, message: 'Failed to save tenant', severity: 'error' });
    }
  };

  const handleUpgrade = async (plan) => {
    try {
      await upgradeTenant(selectedTenant.id, plan);
      setSnackbar({ open: true, message: 'Tenant upgraded successfully', severity: 'success' });
      fetchTenants();
      handleCloseUpgradeDialog();
    } catch (error) {
      console.error('Error upgrading tenant:', error);
      setSnackbar({ open: true, message: 'Failed to upgrade tenant', severity: 'error' });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Tenant Management</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => handleOpenDialog()}
            style={{ marginBottom: '1rem' }}
          >
            Add Tenant
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Domain</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Trial/Subscription</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tenants
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((tenant) => (
                    <TableRow key={tenant.id}>
                      <TableCell>{tenant.name}</TableCell>
                      <TableCell>{tenant.domain}</TableCell>
                      <TableCell>{tenant.status}</TableCell>
                      <TableCell>
                        {tenant.trialEndDate && new Date(tenant.trialEndDate) > new Date() ? (
                          <>
                            Trial ends: {new Date(tenant.trialEndDate).toLocaleDateString()}
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => handleOpenUpgradeDialog(tenant)}
                              style={{ marginLeft: '10px' }}
                            >
                              Upgrade
                            </Button>
                          </>
                        ) : (
                          tenant.subscriptionStatus || 'N/A'
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleOpenDialog(tenant)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleOpenSettingsDialog(tenant)}>
                          <Settings />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tenants.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyledPaper>
      </Grid>
      <TenantDialog
        open={openDialog}
        onClose={handleCloseDialog}
        tenant={selectedTenant}
        onSave={handleSaveTenant}
      />
      <TenantSettingsDialog
        open={openSettingsDialog}
        onClose={handleCloseSettingsDialog}
        tenant={selectedTenant}
      />
      <UpgradeDialog
        open={openUpgradeDialog}
        onClose={handleCloseUpgradeDialog}
        tenant={selectedTenant}
        onUpgrade={handleUpgrade}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

const TenantDialog = ({ open, onClose, tenant, onSave }) => {
  const [tenantData, setTenantData] = useState(tenant || { name: '', domain: '' });

  useEffect(() => {
    setTenantData(tenant || { name: '', domain: '' });
  }, [tenant]);

  const handleChange = (e) => {
    setTenantData({ ...tenantData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(tenantData);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{tenant ? 'Edit Tenant' : 'Add Tenant'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Tenant Name"
            value={tenantData.name}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            name="domain"
            label="Domain"
            value={tenantData.domain}
            onChange={handleChange}
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {tenant ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TenantSettingsDialog = ({ open, onClose, tenant }) => {
  // Implement tenant-specific settings here
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tenant Settings</DialogTitle>
      <DialogContent>
        {/* Add tenant-specific settings fields here */}
        <Typography>Settings for {tenant?.name}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TenantManagement;