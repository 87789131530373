import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const NewSurveyDialog = ({ open, onClose, onSubmit }) => {
  const [surveyData, setSurveyData] = useState({
    title: '',
    description: '',
    targetAudience: '',
    dueDate: '',
  });

  const handleChange = (e) => {
    setSurveyData({ ...surveyData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    onSubmit(surveyData);
    setSurveyData({
      title: '',
      description: '',
      targetAudience: '',
      dueDate: '',
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Survey</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="title"
          label="Survey Title"
          type="text"
          fullWidth
          value={surveyData.title}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={surveyData.description}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="targetAudience"
          label="Target Audience"
          type="text"
          fullWidth
          value={surveyData.targetAudience}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="dueDate"
          label="Due Date"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={surveyData.dueDate}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSurveyDialog;