// src/components/TeamManagement.js
import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getTeamMembers, updateUserRole } from '../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const TeamManagement = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const response = await getTeamMembers();
      setTeamMembers(response.data);
    } catch (error) {
      console.error('Error fetching team members:', error);
      setSnackbar({ open: true, message: 'Failed to fetch team members', severity: 'error' });
    }
  };

  const handleOpenDialog = (member) => {
    setSelectedMember(member);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedMember(null);
    setOpenDialog(false);
  };

  const handleRoleChange = async (event) => {
    const newRole = event.target.value;
    try {
      await updateUserRole(selectedMember.id, newRole);
      setTeamMembers(teamMembers.map(member => 
        member.id === selectedMember.id ? { ...member, role: newRole } : member
      ));
      setSnackbar({ open: true, message: 'Role updated successfully', severity: 'success' });
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating role:', error);
      setSnackbar({ open: true, message: 'Failed to update role', severity: 'error' });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Team Management</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamMembers.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>{member.name}</TableCell>
                    <TableCell>{member.email}</TableCell>
                    <TableCell>{member.role}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleOpenDialog(member)}>
                        Manage
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Manage Team Member</DialogTitle>
        <DialogContent>
          {selectedMember && (
            <>
              <Typography>Name: {selectedMember.name}</Typography>
              <Typography>Email: {selectedMember.email}</Typography>
              <TextField
                select
                fullWidth
                label="Role"
                value={selectedMember.role}
                onChange={handleRoleChange}
                margin="normal"
              >
                <MenuItem value="employee">Employee</MenuItem>
                <MenuItem value="manager">Manager</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </TextField>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default TeamManagement;