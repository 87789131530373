import React from 'react';
import styled from 'styled-components';

const SplashContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #007bff;
  color: white;
`;

const SplashText = styled.h1`
  font-size: 48px;
  font-weight: bold;
`;

const SplashScreen = () => {
  return (
    <SplashContainer>
      <SplashText>pesoHRM</SplashText>
    </SplashContainer>
  );
};

export default SplashScreen;