// src/services/api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Auth
export const login = (email, password) => api.post('/auth/login', { email, password });
export const createTenant = (tenantData) => api.post('/auth/tenants', tenantData);

// Leave
export const getLeaveRequests = () => api.get('/leave');
export const createLeaveRequest = (leaveData) => api.post('/leave', leaveData);
export const approveLeave = (leaveId, status) => api.put(`/leave/${leaveId}`, { status });

// Leave Types
export const getLeaveTypes = () => api.get('/leave-types');
export const createLeaveType = (leaveTypeData) => api.post('/leave-types', leaveTypeData);

// Users
export const getUsers = () => api.get('/users');
export const createUser = (userData) => api.post('/users', userData);
export const updateUser = (userId, userData) => api.put(`/users/${userId}`, userData);
export const getUserProfile = () => api.get('/users/profile');
export const updateUserProfile = (profileData) => api.put('/user/profile', profileData);
export const changePassword = (currentPassword, newPassword) => 
  api.post('/user/change-password', { currentPassword, newPassword });
export const uploadProfilePicture = (formData) => 
  api.post('/users/profile-picture', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
export const getLeaveBalances = () => api.get('/users/leave-balances');

// Holidays
export const getHolidays = () => api.get('/holidays');
export const createHoliday = (holidayData) => api.post('/holidays', holidayData);

// Tenants
export const getTenantInfo = () => api.get('/tenants/info');

// Dashboard
export const fetchDashboardData = () => api.get('/dashboard');

export const generateReport = (reportType) => api.get(`/reports/${reportType}`);
export const exportReport = (reportType, data) => api.post(`/reports/${reportType}/export`, { data });
export const getTeamMembers = () => api.get('/team');
export const updateUserRole = (userId, role) => api.put(`/users/${userId}/role`, { role });
export const getTenants = () => api.get('/tenants');
export const updateTenant = (tenantId, tenantData) => api.put(`/tenants/${tenantId}`, tenantData);
export const upgradeTenant = (tenantId, plan) => api.post(`/tenants/${tenantId}/upgrade`, { plan });
export const deactivateUser = (userId) => api.put(`/users/${userId}/deactivate`);
export const reactivateUser = (userId) => api.put(`/users/${userId}/reactivate`);
export const sendEmail = (to, subject, content) => api.post('/send-email', { to, subject, content });

export const getUserSkills = () => api.get('/skills');
export const addUserSkill = (skillName) => api.post('/skills', { name: skillName });
export const deleteUserSkill = (skillName) => api.delete(`/skills/${skillName}`);
export const updateUserSkills = (skills) => api.put('/skills', { skills });

export const getUserEducation = () => api.get('/user/education');
export const addUserEducation = (educationData) => api.post('/user/education', educationData);
export const updateUserEducation = (id, educationData) => api.put(`/user/education/${id}`, educationData);
export const deleteUserEducation = (id) => api.delete(`/user/education/${id}`);

export const getUserAchievements = () => api.get('/user/achievements');
export const addUserAchievement = (achievementData) => api.post('/user/achievements', achievementData);
export const updateUserAchievement = (id, achievementData) => api.put(`/user/achievements/${id}`, achievementData);
export const deleteUserAchievement = (id) => api.delete(`/user/achievements/${id}`);

export const getUserCertifications = () => api.get('/user/certifications');
export const addUserCertification = (certificationData) => api.post('/user/certifications', certificationData);
export const updateUserCertification = (id, certificationData) => api.put(`/user/certifications/${id}`, certificationData);
export const deleteUserCertification = (id) => api.delete(`/user/certifications/${id}`);

export const getUserPerformance = () => api.get('/user/performance');

export const updatePrivacySettings = (settings) => api.put('/user/privacy-settings', settings);

export const fetchRecruitmentData = () => api.get('/recruitment/dashboard');
export const createJob = (jobData) => api.post('/recruitment/jobs', jobData);

export const fetchOnboardingData = () => api.get('/onboarding');
export const updateOnboardingTask = (onboardingId, taskId, taskData) => 
  api.put(`/onboarding/${onboardingId}/tasks/${taskId}`, taskData);
export const addOnboardingTask = (taskData) => api.post('/onboarding/tasks', taskData);
export const uploadDocument = (onboardingId, taskId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(`/onboarding/${onboardingId}/tasks/${taskId}/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};
export const sendReminder = (employeeId) => api.post(`/onboarding/reminder/${employeeId}`);

// Training and Employee Relations 
export const fetchTrainingData = () => api.get('/training/dashboard');
export const createTraining = (trainingData) => api.post('/training', trainingData);
export const fetchEmployeeRelationsData = () => api.get('/employee-relations/dashboard');
export const createSurvey = (surveyData) => api.post('/employee-relations/surveys', surveyData);

//Upload 
export const uploadEmployees = (file, onUploadProgress) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('/users/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress
  });
};

export const downloadEmployeeTemplate = () => api.get('/users/upload-template', { responseType: 'blob' });
// Attendance
export const fetchAttendanceData = async () => {
  try {
    const response = await api.get('/attendance');
    return response.data;
  } catch (error) {
    console.error('Error fetching attendance data:', error);
    throw error;
  }
};

export const checkIn = async (latitude, longitude) => {
  try {
    const response = await api.post('/attendance/check-in', { latitude, longitude });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'An error occurred during check-in');
    }
    throw error;
  }
};

export const checkOut = async (latitude, longitude) => {
  try {
    const response = await api.put('/attendance/check-out', { latitude, longitude });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'An error occurred during check-out');
    }
    throw error;
  }
};

export const updateAttendance = async (attendanceId, attendanceData) => {
  try {
    const response = await api.put(`/attendance/${attendanceId}`, attendanceData);
    return response.data;
  } catch (error) {
    console.error('Error updating attendance:', error);
    throw error;
  }
};


export default api;