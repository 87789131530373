import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

const NewTrainingDialog = ({ open, onClose, onSubmit }) => {
  const [trainingData, setTrainingData] = useState({
    title: '',
    description: '',
    type: '',
    startDate: '',
    endDate: '',
    instructor: '',
  });

  const handleChange = (e) => {
    setTrainingData({ ...trainingData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    onSubmit(trainingData);
    setTrainingData({
      title: '',
      description: '',
      type: '',
      startDate: '',
      endDate: '',
      instructor: '',
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Training</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="title"
          label="Training Title"
          type="text"
          fullWidth
          value={trainingData.title}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={trainingData.description}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Type</InputLabel>
          <Select
            name="type"
            value={trainingData.type}
            onChange={handleChange}
          >
            <MenuItem value="Online">Online</MenuItem>
            <MenuItem value="In-person">In-person</MenuItem>
            <MenuItem value="Webinar">Webinar</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          name="startDate"
          label="Start Date"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={trainingData.startDate}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="endDate"
          label="End Date"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={trainingData.endDate}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="instructor"
          label="Instructor"
          type="text"
          fullWidth
          value={trainingData.instructor}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewTrainingDialog;