// src/components/ReportsModule.js
import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { generateReport, exportReport } from '../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const reportTypes = [
  { value: 'leaveUsage', label: 'Leave Usage Report' },
  { value: 'employeePerformance', label: 'Employee Performance Report' },
  { value: 'departmentHeadcount', label: 'Department Headcount Report' },
];

const ReportsModule = () => {
  const [selectedReport, setSelectedReport] = useState('');
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleReportChange = (event) => {
    setSelectedReport(event.target.value);
    setReportData(null);
  };

  const handleGenerateReport = async () => {
    setLoading(true);
    try {
      const response = await generateReport(selectedReport);
      setReportData(response.data);
    } catch (error) {
      console.error('Error generating report:', error);
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  const handleExportReport = async () => {
    try {
      await exportReport(selectedReport, reportData);
      // Handle successful export (e.g., show a success message)
    } catch (error) {
      console.error('Error exporting report:', error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Reports Module</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                value={selectedReport}
                onChange={handleReportChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select a report type
                </MenuItem>
                {reportTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleGenerateReport}
                disabled={!selectedReport}
              >
                Generate Report
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleExportReport}
                disabled={!reportData}
              >
                Export Report
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {reportData && (
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              {reportTypes.find((type) => type.value === selectedReport)?.label}
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={reportData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </StyledPaper>
        </Grid>
      )}
    </Grid>
  );
};

export default ReportsModule;