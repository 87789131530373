import React, { useState, useEffect } from 'react';
import { 
  Grid, Typography, CircularProgress, Card, CardContent, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper
} from '@mui/material';
import { fetchAttendanceData } from '../services/api';

const AttendanceDashboard = () => {
  const [attendanceData, setAttendanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAttendanceData();
        setAttendanceData(response);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!attendanceData) return <Typography>No data available</Typography>;

  const { todayAttendance, totalPresent, averageCheckInTime, attendanceRate } = attendanceData;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Attendance Dashboard</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">Total Present</Typography>
            <Typography variant="h4">{totalPresent}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">Average Check-in Time</Typography>
            <Typography variant="h4">{averageCheckInTime}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">Attendance Rate</Typography>
            <Typography variant="h4">{attendanceRate}%</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Employee</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Check-in Time</TableCell>
                  <TableCell>Productivity</TableCell>
                  <TableCell>Mood</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {todayAttendance
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((attendance, index) => (
                    <TableRow key={index}>
                      <TableCell>{attendance.user.name}</TableCell>
                      <TableCell>{attendance.status}</TableCell>
                      <TableCell>{new Date(attendance.checkIn.time).toLocaleTimeString()}</TableCell>
                      <TableCell>{attendance.productivity}</TableCell>
                      <TableCell>{attendance.mood}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={todayAttendance.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AttendanceDashboard;