import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, Button, List, ListItem, ListItemText,
  ListItemIcon, CircularProgress, Box
} from '@mui/material';
import {
  Mood as MoodIcon,
  Assignment as AssignmentIcon,
  Add as AddIcon
} from '@mui/icons-material';
import NewSurveyDialog from './NewSurveyDialog';
import { fetchEmployeeRelationsData, createSurvey } from '../services/api';

const EmployeeRelationsDashboard = () => {
  const [employeeRelationsData, setEmployeeRelationsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openNewSurveyDialog, setOpenNewSurveyDialog] = useState(false);

  useEffect(() => {
    fetchEmployeeRelationsData()
      .then(data => {
        setEmployeeRelationsData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching employee relations data:', error);
        setLoading(false);
      });
  }, []);

  const handleOpenNewSurveyDialog = () => {
    setOpenNewSurveyDialog(true);
  };

  const handleCloseNewSurveyDialog = () => {
    setOpenNewSurveyDialog(false);
  };

  const handleCreateSurvey = (surveyData) => {
    createSurvey(surveyData)
      .then(() => {
        // Refresh employee relations data
        return fetchEmployeeRelationsData();
      })
      .then(data => {
        setEmployeeRelationsData(data);
        handleCloseNewSurveyDialog();
      })
      .catch(error => {
        console.error('Error creating survey:', error);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Employee Relations Dashboard</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Employee Satisfaction</Typography>
          <Typography variant="h3" align="center">
            {employeeRelationsData.satisfactionScore}%
            </Typography>
          <Typography variant="subtitle1" align="center">Overall Satisfaction Score</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Open Issues</Typography>
          <List>
            {employeeRelationsData.openIssues.map((issue) => (
              <ListItem key={issue.id}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={issue.title}
                  secondary={`Status: ${issue.status}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Recent Recognitions</Typography>
          <List>
            {employeeRelationsData.recentRecognitions.map((recognition) => (
              <ListItem key={recognition.id}>
                <ListItemIcon>
                  <MoodIcon />
                </ListItemIcon>
                <ListItemText
                  primary={recognition.employeeName}
                  secondary={recognition.reason}
                />
              </ListItem>
            ))}
          </List>
          <Box mt={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenNewSurveyDialog}
            >
              Create New Survey
            </Button>
          </Box>
        </Paper>
      </Grid>
      <NewSurveyDialog
        open={openNewSurveyDialog}
        onClose={handleCloseNewSurveyDialog}
        onSubmit={handleCreateSurvey}
      />
    </Grid>
  );
};

export default EmployeeRelationsDashboard;