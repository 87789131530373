import React, { useState, useEffect } from 'react';
import { Typography, Stepper, Step, StepLabel, Box, TextField, Button, Grid, MenuItem, Checkbox, FormControlLabel, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTenant } from '../services/api';

const API_URL = process.env.REACT_APP_API_URL;

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const steps = ['Company Details', 'Contact Information', 'Address', 'Customization'];

const PasswordStrengthIndicator = ({ password }) => {
  const getPasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 7) strength += 1;
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength += 1;
    if (password.match(/\d/)) strength += 1;
    if (password.match(/[^a-zA-Z\d]/)) strength += 1;
    return strength;
  };

  const strength = getPasswordStrength(password);

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <LinearProgress
        variant="determinate"
        value={(strength / 4) * 100}
        color={strength < 2 ? 'error' : strength < 3 ? 'warning' : 'success'}
      />
      <Typography variant="caption" color="textSecondary">
        Password strength: {strength === 0 ? 'Weak' : strength === 1 ? 'Fair' : strength === 2 ? 'Good' : strength === 3 ? 'Strong' : 'Very Strong'}
      </Typography>
    </Box>
  );
};

const SignUpForm = ({ setUser }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [countries, setCountries] = useState([]);
  const [colors, setColors] = useState([]);
  const [formData, setFormData] = useState({
    companyName: '',
    domain: '',
    plan: 'free',
    adminName: '',
    adminEmail: '',
    adminPassword: '',
    confirmPassword: '',
    termsAccepted: false,
    country: '',
    phoneNumber: '',
    address: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    primaryColor: '',
    secondaryColor: '',
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(`${API_URL}/tenants/options`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCountries(data.countries || []);
        setColors(data.colors || []);
      } catch (error) {
        console.error('Error fetching tenant options:', error);
      }
    };
    fetchOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'termsAccepted' ? checked : value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [name]: value,
      },
    }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createTenant(formData);
      // Handle successful signup
    } catch (err) {
      // Handle error
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <StyledTextField
              fullWidth
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
            <StyledTextField
              fullWidth
              label="Domain"
              name="domain"
              value={formData.domain}
              onChange={handleChange}
            />
            <StyledTextField
              fullWidth
              select
              name="plan"
              label="Plan"
              value={formData.plan}
              onChange={handleChange}
            >
              <MenuItem value="free">Free</MenuItem>
              <MenuItem value="basic">Basic</MenuItem>
              <MenuItem value="premium">Premium</MenuItem>
            </StyledTextField>
          </>
        );
      case 1:
        return (
          <>
            <StyledTextField
              fullWidth
              label="Admin Name"
              name="adminName"
              value={formData.adminName}
              onChange={handleChange}
            />
            <StyledTextField
              fullWidth
              label="Admin Email"
              name="adminEmail"
              type="email"
              value={formData.adminEmail}
              onChange={handleChange}
            />
            <StyledTextField
              fullWidth
              label="Admin Password"
              name="adminPassword"
              type="password"
              value={formData.adminPassword}
              onChange={handleChange}
            />
            <PasswordStrengthIndicator password={formData.adminPassword} />
            <StyledTextField
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            <StyledTextField
              fullWidth
              select
              name="country"
              label="Country"
              value={formData.country}
              onChange={handleChange}
            >
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </StyledTextField>
            <StyledTextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="termsAccepted"
                  checked={formData.termsAccepted}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="I accept the terms and conditions"
            />
          </>
        );
      case 2:
        return (
          <>
            <StyledTextField
              fullWidth
              label="Street"
              name="street"
              value={formData.address.street}
              onChange={handleAddressChange}
            />
            <StyledTextField
              fullWidth
              label="City"
              name="city"
              value={formData.address.city}
              onChange={handleAddressChange}
            />
            <StyledTextField
              fullWidth
              label="State"
              name="state"
              value={formData.address.state}
              onChange={handleAddressChange}
            />
            <StyledTextField
              fullWidth
              label="Postal Code"
              name="postalCode"
              value={formData.address.postalCode}
              onChange={handleAddressChange}
            />
            <StyledTextField
              fullWidth
              label="Country"
              name="country"
              value={formData.address.country}
              onChange={handleAddressChange}
            />
          </>
        );
      case 3:
        return (
          <>
            <StyledTextField
              fullWidth
              select
              name="primaryColor"
              label="Primary Color"
              value={formData.primaryColor}
              onChange={handleChange}
            >
              {colors.map((color) => (
                <MenuItem key={color} value={color}>
                  <Box
                    component="span"
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: color,
                      display: 'inline-block',
                      marginRight: 1,
                      verticalAlign: 'middle'
                    }}
                  />
                  {color}
                </MenuItem>
              ))}
            </StyledTextField>
            <StyledTextField
              fullWidth
              select
              name="secondaryColor"
              label="Secondary Color"
              value={formData.secondaryColor}
              onChange={handleChange}
            >
              {colors.map((color) => (
                <MenuItem key={color} value={color}>
                  <Box
                    component="span"
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: color,
                      display: 'inline-block',
                      marginRight: 1,
                      verticalAlign: 'middle'
                    }}
                  />
                  {color}
                </MenuItem>
              ))}
            </StyledTextField>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Sign Up
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt={3}>{renderStepContent(activeStep)}</Box>
      <Box mt={3} display="flex" justifyContent="space-between">
        <StyledButton
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Back
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
        >
          {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
        </StyledButton>
      </Box>
    </form>
  );
};

export default SignUpForm;