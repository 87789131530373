// GlobalStyle.js
import { GlobalStyles } from '@mui/material';

const GlobalStyle = (
  <GlobalStyles
    styles={(theme) => ({
      body: {
        margin: 0,
        padding: 0,
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.background.default,
      },
      '*': {
        boxSizing: 'border-box',
      },
    })}
  />
);

export default GlobalStyle;