import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, CircularProgress, Box
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Add as AddIcon } from '@mui/icons-material';
import NewTrainingDialog from './NewTrainingDialog';
import { fetchTrainingData, createTraining } from '../services/api';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const TrainingDashboard = () => {
  const [trainingData, setTrainingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openNewTrainingDialog, setOpenNewTrainingDialog] = useState(false);

  useEffect(() => {
    fetchTrainingData()
      .then(data => {
        setTrainingData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching training data:', error);
        setLoading(false);
      });
  }, []);

  const handleOpenNewTrainingDialog = () => {
    setOpenNewTrainingDialog(true);
  };

  const handleCloseNewTrainingDialog = () => {
    setOpenNewTrainingDialog(false);
  };

  const handleCreateTraining = (trainingData) => {
    createTraining(trainingData)
      .then(() => {
        // Refresh training data
        return fetchTrainingData();
      })
      .then(data => {
        setTrainingData(data);
        handleCloseNewTrainingDialog();
      })
      .catch(error => {
        console.error('Error creating training:', error);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Training Dashboard</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Upcoming Training Sessions</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Participants</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trainingData.upcomingSessions.map((session) => (
                  <TableRow key={session.id}>
                    <TableCell>{session.title}</TableCell>
                    <TableCell>{session.type}</TableCell>
                    <TableCell>{session.date}</TableCell>
                    <TableCell>{session.participants}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenNewTrainingDialog}
            >
              Create New Training
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Training Completion Rates</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={trainingData.completionRates}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {trainingData.completionRates.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
      <NewTrainingDialog
        open={openNewTrainingDialog}
        onClose={handleCloseNewTrainingDialog}
        onSubmit={handleCreateTraining}
      />
    </Grid>
  );
};

export default TrainingDashboard;