import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Container, Card, Panel, StyledButton } from './AuthPage.styles';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';

const API_URL = process.env.REACT_APP_API_URL;

const AuthPage = ({ setUser }) => {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [countries, setCountries] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(`${API_URL}/tenants/options`);
        const options = await response.json();
        setCountries(options.countries || []);
        setColors(options.colors || []);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
    fetchOptions();
  }, []);

  const toggleForm = () => {
    setIsLoginForm(!isLoginForm);
  };

  return (
    <Container>
      <Card isLoginForm={isLoginForm}>
        <Panel isForm={!isLoginForm} isDark={false}>
          <Typography variant="h4" gutterBottom color="primary">
            {isLoginForm ? "LOGIN" : "SIGN UP"}
          </Typography>
          {isLoginForm ? (
            <LoginForm setUser={setUser} />
          ) : (
            <SignUpForm setUser={setUser} countries={countries} colors={colors} />
          )}
        </Panel>
        <Panel isForm={isLoginForm} isDark={true}>
          <Typography variant="h4" gutterBottom>
            {isLoginForm ? "Don't have an account?" : "Have an account?"}
          </Typography>
          <Typography variant="body1" paragraph>
          {isLoginForm ? "Create your account here..Its Quick and Easy!" : "Lets Jump back in..."}
          </Typography>
          <StyledButton variant="outlined" color="inherit" onClick={toggleForm}>
            {isLoginForm ? "SIGN UP" : "LOGIN"}
          </StyledButton>
        </Panel>
      </Card>
    </Container>
  );
};

export default AuthPage;