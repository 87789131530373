import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { StyledTextField, StyledButton } from './AuthPage.styles';
import { login } from '../services/api';

const LoginForm = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      setUser(response.data.user);
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Log In
      </Typography>
      <StyledTextField
        fullWidth
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <StyledTextField
        fullWidth
        label="Password"
        type="password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
      <StyledButton type="submit" fullWidth variant="contained">
        Log In
      </StyledButton>
      <Typography variant="body2" style={{ marginTop: '1rem', textAlign: 'center' }}>
        Forgot password?
      </Typography>
    </form>
  );
};

export default LoginForm;